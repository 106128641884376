// PlanManager.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../constants/apiConfig';

const PlanManager = ({ customerId }) => {
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlanData = async () => {
      if (customerId) {
        setIsLoading(true);
        try {
          const currentPlanResponse = await fetch(`${API_BASE_URL}/api/current-plan?customer_id=${customerId}`);

          if (!currentPlanResponse.ok) {
            throw new Error('Failed to fetch plan data');
          }

          const currentPlanData = await currentPlanResponse.json();
          setCurrentPlan(currentPlanData);
        } catch (error) {
          console.error('Error fetching plan data:', error);
          setError('Failed to load plan information. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPlanData();
  }, [customerId]);

  const handleContactSupport = () => {
    navigate('/support');
  };

  if (isLoading) {
    return <div>Loading plan information...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div className="plan-manager">
      {currentPlan && currentPlan.planName ? (
        <div>
          <h4>Current Plan</h4>
          <p><strong>Plan Name:</strong> {currentPlan.planName}</p>
          <p><strong>Monthly Credits:</strong> {currentPlan.creditsPerMonth || 'N/A'}</p>
          <p>
            <strong>Price: </strong> 
            {currentPlan.price != null  // Changed from !== undefined to != null
              ? `$${Number(currentPlan.price).toFixed(2)}/${currentPlan.planName === "Pay-as-you-go" ? "credit" : "month"}`
              : 'N/A'}
          </p>
          <button onClick={handleContactSupport} className="btn btn-primary">
            Contact Support to Change Plan
          </button>
        </div>
      ) : (
        <div>
          <p>You are not currently subscribed to any plan.</p>
          <button onClick={handleContactSupport} className="btn btn-primary">
            Contact Support
          </button>
        </div>
      )}
    </div>
);
};

export default PlanManager;