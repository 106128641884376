import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Support.css';
import { authApi } from '../../constants/axiosConfig';

const Support = () => {
  const [openSection, setOpenSection] = useState(null);
  const [formData, setFormData] = useState({
    feedback: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const location = useLocation();
  
  // Get user data from Redux store
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('openSection');
    
    setTimeout(() => {
      setOpenSection(section || 'contact');
    }, 100);
  }, [location]);

  const toggleSection = (section) => {
    if (openSection === section) {
      setOpenSection(null);
    } else {
      setOpenSection(section);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);

    const submissionData = {
      phone: user?.phone || '',
      feedback: formData.feedback
    };

    try {
      const response = await authApi.post('/api/support/submit-feedback', submissionData);

      if (response.data.success) {
        setSubmitStatus({ type: 'success', message: response.data.message || 'Feedback submitted successfully' });
        setFormData({ feedback: '' });
      } else {
        throw new Error(response.data.message || 'Submission failed');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitStatus({ 
        type: 'error', 
        message: error.response?.data?.message || 'An unexpected error occurred. Please try again later.' 
      });
    }
  };

  const renderSection = (sectionName, title, content) => {
    const isOpen = openSection === sectionName;
    return (
      <section className={`${sectionName}-section`}>
        <div className="section-header" onClick={() => toggleSection(sectionName)}>
          <h2>{title}</h2>
          <span className={`dropdown-icon ${isOpen ? 'open' : ''}`}>
            {isOpen ? '−' : '+'}
          </span>
        </div>
        <div className={`section-content ${isOpen ? 'open' : ''}`}>
          {content}
        </div>
      </section>
    );
  };

  return (
    <div className="support-page">
      <h1>Support</h1>
      
      {renderSection('contact', 'Contact Us', (
        <div>
          <p>You can reach our support team at:</p>
          <p>Email: <a href="mailto:support@bridgepointai.com">support@bridgepointai.com</a></p>
          <p>Phone: <a href="tel:+14358176114">+1 (435) 817-6114</a></p>
        </div>
      ))}
      
      {renderSection('feedback', 'Feedback', (
        <div>
          <div className="feedback-intro">
          </div>
          <form onSubmit={handleSubmit}>
            <textarea 
              name="feedback" 
              placeholder="Share your experience, suggestions, or concerns..." 
              rows="5" 
              required 
              value={formData.feedback}
              onChange={handleInputChange}
            ></textarea>
            <button type="submit">Submit Feedback</button>
            {submitStatus && (
              <p className={`submit-status ${submitStatus.type}`}>
                {submitStatus.message}
              </p>
            )}
          </form>
        </div>
      ))}
    </div>
  );
};

export default Support;