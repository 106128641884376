import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MobileSidebar.css';

const MobileSidebar = ({ isOpen, toggleSidebar, companyLogo }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    toggleSidebar();
    navigate(path);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.mobile-sidebar') && !event.target.closest('.hamburger-menu')) {
        toggleSidebar();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <>
      <div className={`mobile-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <img src={companyLogo} alt="Company Logo" className="logo" />
          <span className="company-name">BridgePoint AI</span>
          <button className="demo-button" onClick={() => handleNavigation('/demo')}>See How It Works</button>
          <button className="btn btn-lg btn-primary" onClick={() => handleNavigation('/login')}>Log In</button>
          <button className="btn btn-lg btn-primary" onClick={() => handleNavigation('/signup')}>Sign Up</button>
          <button className="btn btn-lg btn-outline-secondary" onClick={() => handleNavigation('/')}>Home</button>
        </div>
      </div>
      <button className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        {isOpen ? '×' : '☰'}
      </button>
    </>
  );
};

export default MobileSidebar;