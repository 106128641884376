// src/modules/Analytics/Components/PageTracker.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import TimelineFilter, { calculateDateRange } from '../TimelineFilter';
import API_BASE_URL from '../../../constants/apiConfig';

const PageTracker = ({ isVisible }) => {
  const [pageViewData, setPageViewData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('1d');
  const [selectedEnvironment, setSelectedEnvironment] = useState('prod');

  const fetchPageViewData = useCallback(async (range) => {
    if (!isVisible) return;
    
    setIsLoading(true);
    try {
      const { startDate, endDate } = calculateDateRange(range, selectedEnvironment);
      const params = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
        environment: selectedEnvironment
      });
      
      const response = await axios.get(`${API_BASE_URL}/api/metrics/views?${params.toString()}`, {
        withCredentials: true,
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      });

      if (response.data.success) {
        setPageViewData(response.data.data || {});
        setError(null);
      } else {
        throw new Error(response.data.message || 'Failed to fetch page view data');
      }
    } catch (err) {
      console.error('Error fetching page views:', err);
      if (err.response) {
        console.error('Response data:', err.response.data);
        console.error('Response status:', err.response.status);
      }
      setError('Unable to load page view data. Please try again later.');
      setPageViewData({});
    } finally {
      setIsLoading(false);
    }
  }, [isVisible, selectedEnvironment]);

  useEffect(() => {
    if (isVisible) {
      fetchPageViewData(selectedRange);
      const interval = setInterval(() => fetchPageViewData(selectedRange), 60000);
      return () => clearInterval(interval);
    }
  }, [selectedRange, fetchPageViewData, isVisible]);

  if (!isVisible) {
    return null;
  }

  const renderContent = () => {
    if (error) {
      return (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="text-center p-4">
          <Spinner animation="border" role="status" />
          <p className="mt-2">Loading page view data...</p>
        </div>
      );
    }

    if (!pageViewData || Object.keys(pageViewData).length === 0) {
      return (
        <div className="alert alert-info">
          No page view data available for the selected time range
        </div>
      );
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Page Path</th>
              <th>Total Views</th>
              <th>Top Referrers</th>
              <th>Most Recent View</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(pageViewData).map(([path, stats]) => (
              <tr key={path}>
                <td>{path}</td>
                <td>{stats.total_views || 0}</td>
                <td>
                  <ul className="list-unstyled mb-0">
                    {Object.entries(stats.referrers || {})
                      .sort((a, b) => b[1] - a[1])
                      .slice(0, 3)
                      .map(([referrer, count]) => (
                        <li key={referrer}>
                          {referrer === 'direct' ? 'Direct' : referrer}: {count}
                        </li>
                      ))}
                  </ul>
                </td>
                <td>
                  {stats.recent_views?.[0]?.timestamp
                    ? new Date(stats.recent_views[0].timestamp).toLocaleString()
                    : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="page-views-dashboard">
      <div className="row mb-4">
        <div className="col-12 mb-4">
          <div className="dashboard-card">
            <h3 className="card-title">Page View Metrics</h3>
            <div className="card-content">
              <div className="d-flex justify-content-between align-items-center">
                <TimelineFilter
                  selectedRange={selectedRange}
                  onRangeChange={setSelectedRange}
                  refreshButton={true}
                  onRefresh={() => fetchPageViewData(selectedRange)}
                  isLoading={isLoading}
                  selectedEnvironment={selectedEnvironment}
                />
                <select
                  className="form-select ms-3"
                  style={{ width: 'auto' }}
                  value={selectedEnvironment}
                  onChange={(e) => setSelectedEnvironment(e.target.value)}
                >
                  <option value="prod">Production</option>
                  <option value="dev">Development</option>
                  <option value="staging">Staging</option>
                </select>
              </div>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTracker;