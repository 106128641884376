// src/modules/Analytics/hooks/useAnalyticsData.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';
import { calculateDateRange } from '../TimelineFilter';

export const useAnalyticsData = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [checkpointData, setCheckpointData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('1d');
  const [selectedEnvironment, setSelectedEnvironment] = useState('prod');
  const [showRequestTable, setShowRequestTable] = useState(false);
  const [highlightedRequestId, setHighlightedRequestId] = useState(null);
  const [isLoading, setIsLoading] = useState({
    initial: true,
    refresh: false
  });

  const fetchAnalyticsData = useCallback(async (range) => {
    setIsLoading(prev => ({
      ...prev,
      initial: prev.initial,
      refresh: !prev.initial
    }));
    
    try {
      const { startDate, endDate } = calculateDateRange(range, selectedEnvironment);
      
      const params = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
        environment: selectedEnvironment
      });
      
      if (range) {
        params.append('range', range);
      }
      
      const [countsResponse, checkpointsResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/metrics/counts?${params.toString()}`, {
          withCredentials: true
        }),
        axios.get(`${API_BASE_URL}/api/metrics/checkpoints?${params.toString()}`, {
          withCredentials: true
        })
      ]);

      if (!countsResponse.data.success) {
        throw new Error(countsResponse.data.message || 'Failed to fetch analytics data');
      }

      if (!checkpointsResponse.data.success) {
        throw new Error(checkpointsResponse.data.message || 'Failed to fetch checkpoint data');
      }

      setAnalyticsData(countsResponse.data.data);
      setCheckpointData(checkpointsResponse.data.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching analytics:', err);
      if (err.response) {
        console.error('Response data:', err.response.data);
        console.error('Response status:', err.response.status);
      }
      setError(err.message || 'Error loading analytics data');
      setAnalyticsData(null);
      setCheckpointData(null);
    } finally {
      setIsLoading({
        initial: false,
        refresh: false
      });
    }
  }, [selectedEnvironment]);

  useEffect(() => {
    fetchAnalyticsData(selectedRange);
  }, [selectedRange, selectedEnvironment, fetchAnalyticsData]);

  return {
    analyticsData,
    checkpointData,
    error,
    isLoading,
    fetchAnalyticsData,
    selectedRange,
    setSelectedRange,
    selectedEnvironment,
    setSelectedEnvironment,
    showRequestTable,
    setShowRequestTable,
    highlightedRequestId,
    setHighlightedRequestId
  };
};