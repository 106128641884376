import React from 'react';
import './Legal.css';

const Legal = () => {
    return (
      <div className="legal-page">
        <div className="title-section">
          <h1 className="legal-title">Privacy Policy and End User License Agreement</h1>
          <h1 className="company-name">Bridgepoint AI, Inc.</h1>
          <p className="last-updated">Last updated: 2024-09-03</p>
        </div>
        <div className="legal-container">
          <div className="legal-sections">
            <section className="privacy-policy">
              <h2 className="section-title">Privacy Policy</h2>
              <div className="policy-content">
                <p>
                  Bridgepoint AI, Inc. operates a software change order creation and management 
              service via text messaging and through our website. This Privacy Policy applies 
              to all users of our service across the United States.
            </p>
            
            <h3>Information Collection</h3>
            <p>We collect the following types of data:</p>
            <ul>
              <li>Contact information (e.g., phone number, email address)</li>
              <li>Usage data (e.g., texting patterns, frequency of use)</li>
              <li>Change order details</li>
              <li>Device information</li>
              <li>Cookies and similar technologies</li>
            </ul>
            
            <p>We collect this information through:</p>
            <ul>
              <li>Direct user input</li>
              <li>Automated data collection when you use our service</li>
              <li>Third-party sources (with your consent)</li>
            </ul>

            <h3>Use of Information</h3>
            <p>We use collected data to:</p>
            <ul>
              <li>Provide and improve our service</li>
              <li>Communicate with users</li>
              <li>Analyze usage patterns</li>
              <li>Comply with legal obligations</li>
            </ul>

            <p>The legal basis for processing includes:</p>
            <ul>
              <li>Performance of our service contract with you</li>
              <li>Your consent</li>
              <li>Our legitimate business interests</li>
            </ul>

            <h3>Data Sharing</h3>
            <p>We may share data with:</p>
            <ul>
              <li>Service providers and business partners</li>
              <li>Legal and regulatory authorities</li>
              <li>Potential buyers or investors (in anonymized form)</li>
            </ul>

            <p>Data is shared for:</p>
            <ul>
              <li>Service delivery and improvement</li>
              <li>Legal compliance</li>
              <li>Business operations</li>
            </ul>

            <h3>Data Security</h3>
            <p>We implement industry-standard security measures, including:</p>
            <ul>
              <li>Encryption of data in transit and at rest</li>
              <li>Regular security audits</li>
              <li>Employee training on data protection</li>
            </ul>

            <h3>User Rights</h3>
            <p>You have the right to:</p>
            <ul>
              <li>Access and correct your personal information</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of certain data uses</li>
              <li>Additional rights for residents of specific states (detailed in "State-Specific Provisions")</li>
            </ul>

            <h3>State-Specific Provisions</h3>
            <h4>California (CCPA/CPRA):</h4>
            <ul>
              <li>Right to know what personal information is collected</li>
              <li>Right to delete personal information</li>
              <li>Right to opt-out of sale of personal information</li>
              <li>Right to non-discrimination for exercising these rights</li>
            </ul>

            <h4>Virginia (VCDPA):</h4>
            <ul>
              <li>Right to confirm whether personal data is being processed</li>
              <li>Right to access personal data</li>
              <li>Right to correct inaccuracies</li>
              <li>Right to delete personal data</li>
              <li>Right to obtain a copy of personal data</li>
              <li>Right to opt-out of targeted advertising, sale of personal data, and profiling</li>
            </ul>

            <h4>Colorado (CPA):</h4>
            <p>Similar rights to VCDPA, plus the right to opt-out via a user-selected universal opt-out mechanism</p>

            <h4>Utah (UCPA):</h4>
            <ul>
              <li>Right to access and delete personal data</li>
              <li>Right to opt-out of the sale of personal data or use for targeted advertising</li>
            </ul>

            <h3>Children's Privacy</h3>
            <p>Our service is not intended for children under 13. We do not knowingly collect data from children under 13 and comply with COPPA.</p>

            <h3>Changes to Policy</h3>
            <p>We may update this policy periodically. We will notify users of significant changes via text message or email.</p>

            <h3>Contact Information</h3>
            <p>
              For privacy-related inquiries, contact us at:<br />
              Bridgepoint AI, Inc.<br />
              Email: support@bridgepointai.com
            </p>

            <h3>Use of Cookies</h3>
            <p>
              Our website uses cookies to enhance your user experience and improve our service. 
              Cookies are small text files stored on your device that help us recognize you and your preferences.
            </p>

            <p>Types of cookies we use:</p>
            <ul>
              <li>Essential cookies: These are necessary for the website to function properly.</li>
              <li>Functionality cookies: These remember your choices and preferences.</li>
            </ul>

            <p>
              You can control and/or delete cookies as you wish. You can delete all cookies that are 
              already on your computer and you can set most browsers to prevent them from being placed. 
              However, if you do this, you may have to manually adjust some preferences every time you 
              visit our website and some services and functionalities may not work.
            </p>

            <p>
              For more information about cookies, including how to see what cookies have been set and 
              how to manage and delete them, visit www.allaboutcookies.org.
            </p>

            <p>
              By continuing to use our website, you agree to our use of cookies as described in this policy.
            </p>
          </div>
        </section>

        <section className="eula">
          <h2 className="section-title">End-User License Agreement (EULA)</h2>
          <div className="eula-content">
            <h3>Acceptance of Terms</h3>
            <p>
              By using change order creation and management software belonging to 
              Bridgepoint AI, Inc., including but not limited to the website 
              (changeorderassistant.com) and associated mobile texting services, 
              you agree to be bound by this EULA.
            </p>

            <h3>1. License Grant</h3>
            <p>
              We grant you a limited, non-exclusive, non-transferable license to use 
              the Service, including its website and mobile texting features, for your 
              internal business purposes.
            </p>

            <h3>2. Restrictions on Use</h3>
            <p>You may not:</p>
            <ul>
              <li>Reverse engineer or attempt to extract the source code of our Service</li>
              <li>Use the Service for any illegal activities</li>
              <li>Resell or redistribute the Service</li>
              <li>Use the Service in a way that could damage or overburden our systems</li>
            </ul>

            <h3>3. Mobile Texting Service</h3>
            <p>
              Our Service includes a mobile texting feature. By using this feature, you agree 
              to receive text messages related to the Service. Standard message and data rates 
              may apply. You are responsible for any fees charged by your mobile carrier in 
              connection with your use of the texting service.
            </p>

            <h3>4. Intellectual Property Rights</h3>
            <p>
              All intellectual property rights in the Service remain the property of 
              Bridgepoint AI, Inc. You may not use our trademarks or other intellectual 
              property without our prior written consent.
            </p>

            <h3>5. User-Generated Content</h3>
            <p>
              You retain ownership of any content you submit through the Service, including 
              via the website or mobile texting feature. By submitting content, you grant us 
              a worldwide, royalty-free license to use, reproduce, and distribute that content 
              in connection with the Service.
            </p>

            <h3>6. Termination Conditions</h3>
            <p>
              We may terminate or suspend your access to the Service immediately, without 
              prior notice, for any breach of this Agreement. Upon termination, your right 
              to use the Service will immediately cease.
            </p>

            <h3>7. Disclaimers and Limitations of Liability</h3>
            <p>
              The Service is provided "as is" without any warranties. We shall not be liable 
              for any indirect, incidental, special, consequential, or punitive damages.
            </p>

            <h3>8. Governing Law and Jurisdiction</h3>
            <p>
              This Agreement is governed by the laws of the State of Utah. However, this choice 
              of law may not deprive you of the protection afforded under the laws of your state 
              of residence where such laws cannot be derogated by contract.
            </p>

            <h3>9. Dispute Resolution</h3>
            <p>
              Any dispute arising from this Agreement will be resolved through binding arbitration 
              conducted by the American Arbitration Association. You may opt-out of this arbitration 
              provision within 30 days of accepting this Agreement by emailing support@bridgepointai.com.
            </p>

            <h3>10. Updates to Terms</h3>
            <p>
              We may update these terms periodically. Continued use of the Service after such 
              changes constitutes acceptance of the new terms.
            </p>

            <h3>11. Material Price Lookup and Liability</h3>
                <ol type="a">
                <li>
                    The Service may include features for material price lookup and estimation. 
                    While we strive to provide accurate and up-to-date information, these prices 
                    are estimates and may not reflect real-time market conditions.
                </li>
                <li>
                    You acknowledge that material prices are subject to fluctuation and that 
                    the Service's estimates may not always be accurate. It is your responsibility 
                    to verify prices with suppliers before making any financial commitments or 
                    decisions based on these estimates.
                </li>
                <li>
                    Bridgepoint AI, Inc. shall not be liable for any losses, damages, or costs 
                    incurred as a result of reliance on material price estimates provided by the 
                    Service. This includes, but is not limited to, losses due to incorrect pricing 
                    in change orders, project bids, or other business decisions.
                </li>
                <li>
                    In the event of a significant discrepancy between our estimate and the actual 
                    price, we encourage you to report this to our customer support team. While we 
                    are not obligated to do so, we may, at our discretion, investigate and update 
                    our pricing database to improve future estimates.
                </li>
                <li>
                    This clause does not affect your statutory rights as a consumer where applicable 
                    law prohibits such limitations.
                </li>
                </ol>

            <h3>Acceptance of Terms (Reaffirmation)</h3>
            <p>
            By using change order creation and management software belonging to 
              Bridgepoint AI, Inc., including but not limited to the website 
              (changeorderassistant.com) and associated mobile texting services, 
              you agree to be bound by this EULA.
            </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Legal;