// src/modules/shared/FormatPhoneNumber.js

export const formatPhoneNumberForDisplay = (value) => {
    if (!value) return value;
    // Remove all non-digit characters
    const phoneNumber = value.replace(/[^\d]/g, '');
    // Remove the leading '1' if present
    const normalizedNumber = phoneNumber.startsWith('1') ? phoneNumber.slice(1) : phoneNumber;
    const phoneNumberLength = normalizedNumber.length;
    if (phoneNumberLength < 4) return normalizedNumber;
    if (phoneNumberLength < 7) {
      return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    }
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)}-${normalizedNumber.slice(6, 10)}`;
  };
  
  export const formatPhoneNumberForE164 = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    return `+1${phoneNumber.slice(-10)}`;
  };