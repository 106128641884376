// src/modules/Analytics/Components/RouterAnalytics/RequestTable.js
import React, { useEffect, useRef } from 'react';
import { formatTimestamp } from '../../utils/dateFormatters';

export const RequestTable = ({ requests, highlightedRequestId }) => {
  const rowRefs = useRef({});
  const filteredRequests = requests.filter(record => record.checkpoints.router !== undefined);

  useEffect(() => {
    if (highlightedRequestId && rowRefs.current[highlightedRequestId]) {
      rowRefs.current[highlightedRequestId].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [highlightedRequestId]);

  return (
    <div className="mt-4">
      <h4>Individual Request Details</h4>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Request ID</th>
              <th>Processing Time (s)</th>
            </tr>
          </thead>
          <tbody>
            {filteredRequests.map(record => (
              <tr 
                key={record.request_id}
                ref={el => rowRefs.current[record.request_id] = el}
                className={highlightedRequestId === record.request_id ? 'table-primary' : ''}
              >
                <td>{formatTimestamp(record.timestamp, 'full')}</td>
                <td>{record.request_id}</td>
                <td>{record.checkpoints.router.toFixed(3)}</td>
              </tr>
            ))}
            {filteredRequests.length === 0 && (
              <tr>
                <td colSpan="3" className="text-center">
                  No requests with router data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};