// Assistant/MessageList.js
import React from 'react';
import Message from './Message';

const MessageList = ({ messages, isTyping, displayedText, messageListRef, isHistoryLoading }) => {
  const filterDuplicateMessages = (messages) => {
    return messages.filter((message, index, array) => {
      if (index === 0) return true;
      const prevMessage = array[index - 1];
      return !(message.isUser && prevMessage.isUser && message.text === prevMessage.text);
    });
  };

  const filteredMessages = filterDuplicateMessages(messages);

  return (
    <div className="chat-body" ref={messageListRef}>
      {isHistoryLoading && <div className="loading-message">Chat history loading...</div>}
      {filteredMessages.map((message, index) => (
        <Message
          key={index}
          message={message}
          isTyping={isTyping && index === filteredMessages.length - 1}
          displayedText={index === filteredMessages.length - 1 ? displayedText : null}
          isLast={index === filteredMessages.length - 1}
        />
      ))}
    </div>
  );
};

export default MessageList;