// src/modules/Analytics/Components/RouterAnalytics/RouterStats.js 
import React from 'react';
import TimelineFilter from '../../TimelineFilter';

export const TimelineSection = ({ 
  selectedRange, 
  onRangeChange, 
  onRefresh, 
  isLoading 
}) => {
  return (
    <div className="row mb-4">
      <div className="col-12 mb-4">
        <div className="dashboard-card">
          <h3 className="card-title">Time Range</h3>
          <div className="card-content">
            <TimelineFilter
              selectedRange={selectedRange}
              onRangeChange={onRangeChange}
              refreshButton={true}
              onRefresh={onRefresh}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const RouterStats = ({ checkpointStats }) => {
  if (!checkpointStats?.router) {
    return <div className="alert alert-info">No router statistics available</div>;
  }

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Router Name</th>
            <th>Usage Count</th>
            <th>Average Time (seconds)</th>
            <th>Min Time (seconds)</th>
            <th>Max Time (seconds)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>router</td>
            <td>{checkpointStats.router.count}</td>
            <td>{checkpointStats.router.avg_time.toFixed(3)}</td>
            <td>{checkpointStats.router.min_time.toFixed(3)}</td>
            <td>{checkpointStats.router.max_time.toFixed(3)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};