// src/components/ConfirmationModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ 
  show, 
  handleClose, 
  handleConfirm, 
  message, 
  confirmButtonText, 
  showCancelButton = true 
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {showCancelButton && (
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button 
          variant="primary" 
          onClick={handleConfirm}
          disabled={message === 'Cancelling change order...'}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;