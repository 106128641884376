import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.changeorderassistant.com';

export const authApi = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});