import axios from 'axios';
import API_BASE_URL from './constants/apiConfig';

class EventTracker {
    static async trackEvent(eventData) {
        if (!eventData.user || !eventData.user.customer_id || !eventData.user.phone) {
            console.warn('Invalid user object for event tracking:', eventData.user);
            return;
        }

        // Skip tracking for impersonated sessions
        if (eventData.user.isImpersonated) {
            return;
        }

        const { user, event_name, element_identifier } = eventData;

        try {
            const payload = {
                event_name,
                customer_id: user.customer_id,
                phone_number: user.phone,
                element_identifier
            };

            await axios.post(`${API_BASE_URL}/events`, payload, {
                withCredentials: true
            });
        } catch (error) {
            console.error(`Error tracking ${event_name}:`, error);
        }
    }

    static async trackPageView(user, pageName) {
        if (!pageName) {
            pageName = window.location.pathname;
        }
        
        await this.trackEvent({
            user,
            event_name: 'page_view',
            element_identifier: pageName
        });
    }

    static async trackButtonClick(user, buttonIdentifier) {
        await this.trackEvent({
            user,
            event_name: 'button_click',
            element_identifier: buttonIdentifier
        });
    }

    static async trackLogin(user) {
        await this.trackEvent({
            user,
            event_name: 'login'
        });
    }
}

export default EventTracker;