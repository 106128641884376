// src/modules/ChangeOrderComponents/services/ChangeOrderDetails.js
import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';
import { debounce } from 'lodash';

export const fetchChangeOrderDetails = (customerId, corNumber, isNewOrder = false) => {
  return new Promise((resolve, reject) => {
    debouncedFetchChangeOrderDetails(customerId, corNumber, isNewOrder, resolve, reject);
  });
};

const debouncedFetchChangeOrderDetails = debounce(async (customerId, corNumber, isNewOrder, resolve, reject) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/change-order-details/fetch`, {
      params: {
        customer_id: customerId,
        cor_number: corNumber,
        is_new_order: isNewOrder,
      },
    });
    const { bigDict, jobOptions } = response.data;
    resolve({ data: { changeOrderDetails: { bigDict, ...response.data }, jobOptions }, error: null });
  } catch (error) {
    console.error('Error fetching change order details:', error);
    reject({ data: null, error: error });
  }
}, 300);

export const updateChangeOrderDetails = async (customerId, phoneNumber, corNumber, updatedData, isNewOrder = false) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/change-order-details/update`, {
      customer_id: customerId,
      cor_number: corNumber,
      bigDict: updatedData,
      is_new_order: isNewOrder,
      phone_number: phoneNumber
    });
    return response;
  } catch (error) {
    console.error('Error updating change order details:', error);
    throw error;
  }
};