/* Used by: modules/Assistant/Assistant.js */

// src/modules/AssistantLoadingDots.js
import React from 'react';
import './LoadingDots.css';

const AssistantLoadingDots = () => {
  return (
    <div className="assistant-loading-dots">
      <div className="assistant-loading-dots__dot assistant-loading-dots__dot--first"></div>
      <div className="assistant-loading-dots__dot assistant-loading-dots__dot--second"></div>
      <div className="assistant-loading-dots__dot"></div>
    </div>
  );
};

export default AssistantLoadingDots;