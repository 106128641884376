/* Used by: redux/store.js */

// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import changeOrderReducer from '../../modules/ChangeOrderComponents/redux/changeOrderReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  user: userReducer,
  changeOrder: changeOrderReducer
});

export default rootReducer;