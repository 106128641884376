import React, { useState } from 'react';
import './TimelineFilter.css';

const timeRanges = [
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 90 Days', value: '90' },
  { label: 'Last 180 Days', value: '180' },
  { label: 'Last 365 Days', value: '365' },
  { label: 'All Time', value: 'all' }
];

const TimelineFilter = ({ selectedRange, onFilterChange }) => {
  const [tempRange, setTempRange] = useState(selectedRange);

  const handleRangeChange = (event) => {
    setTempRange(event.target.value);
  };

  const handleApply = () => {
    onFilterChange(tempRange);
  };

  return (
    <div className="timeline-filter-component">
      <div className="timeline-filter d-flex align-items-center">
        <label htmlFor="timeRange" className="me-2">Select Time Range:</label>
        <select
          id="timeRange"
          value={tempRange}
          onChange={handleRangeChange}
          className="form-select me-2"
        >
          {timeRanges.map((range) => (
            <option key={range.value} value={range.value}>
              {range.label}
            </option>
          ))}
        </select>
        <button onClick={handleApply} className="btn btn-primary">Apply</button>
      </div>
    </div>
  );
};

export default TimelineFilter;