export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SET_DEVELOPER_LOGGED_IN = 'SET_DEVELOPER_LOGGED_IN';
export const CLEAR_DEVELOPER_LOGGED_IN = 'CLEAR_DEVELOPER_LOGGED_IN';
export const SET_DEVELOPER_IMPERSONATING = 'SET_DEVELOPER_IMPERSONATING';
export const CLEAR_DEVELOPER_IMPERSONATING = 'CLEAR_DEVELOPER_IMPERSONATING';

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  payload: user,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT
});

export const setDeveloperLoggedIn = () => ({
  type: SET_DEVELOPER_LOGGED_IN
});

export const clearDeveloperLoggedIn = () => ({
  type: CLEAR_DEVELOPER_LOGGED_IN
});

export const setDeveloperImpersonating = (isImpersonating) => ({
  type: SET_DEVELOPER_IMPERSONATING,
  payload: isImpersonating,
});

export const clearDeveloperImpersonating = () => ({
  type: CLEAR_DEVELOPER_IMPERSONATING
});