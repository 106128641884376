import React, { useState, useEffect, useCallback } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Sector } from 'recharts';
import './SharedPieCharts.css';

const COLORS = {
  'Cancelled': 'var(--chart-color-1)',
  'Executed': 'var(--chart-color-2)',
  'Unexecuted': 'var(--chart-color-3)',
  'Incomplete': 'var(--chart-color-4)',
  'Canceled': 'var(--chart-color-1)'
};

const FALLBACK_COLORS = [
  'var(--chart-color-5)',
  'var(--chart-color-6)',
];

const CustomLegend = ({ payload }) => (
  <div className="pie-chart-legend">
    {payload.map((entry, index) => (
      <div key={`legend-${index}`} className="legend-item">
        <span
          className="legend-color"
          style={{ backgroundColor: entry.color }}
        />
        <span className="legend-label">{entry.value}</span>
      </div>
    ))}
  </div>
);

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`Count: ${payload.count}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={36} textAnchor={textAnchor} fill="#999">
        Total Value:
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={54} textAnchor={textAnchor} fill="#999">
        {`$${Number(payload.totalValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
      </text>
    </g>
  );
};

const SharedPieChart = ({ data, width = "100%", height = "100%" }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  let fallbackColorIndex = 0;
  
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const onPieEnter = useCallback((_, index) => {
    if (!isMobile) {
      setActiveIndex(index);
    }
  }, [isMobile]);

  const onPieLeave = useCallback(() => {
    if (!isMobile) {
      setActiveIndex(null);
    }
  }, [isMobile]);

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text 
        x={x} 
        y={y} 
        fill="white" 
        textAnchor="middle" 
        dominantBaseline="central"
        className="pie-chart-label"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const pieProps = isMobile ? {} : {
    activeIndex,
    activeShape: renderActiveShape,
    onMouseEnter: onPieEnter,
    onMouseLeave: onPieLeave
  };
  
  return (
    <div className="pie-chart-wrapper" style={{ width, height }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius="70%"
            fill="#8884d8"
            dataKey="value"
            {...pieProps}
          >
            {data.map((entry, index) => {
              let color = COLORS[entry.name] || FALLBACK_COLORS[fallbackColorIndex++ % FALLBACK_COLORS.length];
              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Pie>
          {isMobile && <Legend content={<CustomLegend />} />}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SharedPieChart;