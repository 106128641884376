import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Select from 'react-select';
import './BillingForm.css';

const BillingForm = ({ onSubmit, plans, setIsTestAccount, initialData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [billingEmail, setBillingEmail] = useState(initialData.email || '');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isCustomPlan, setIsCustomPlan] = useState(false);
  const [customCredits, setCustomCredits] = useState('');
  const [customCreditsError, setCustomCreditsError] = useState('');
  const [policyAcknowledged, setPolicyAcknowledged] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomCreditsChange = (e) => {
    const value = e.target.value;
    setCustomCredits(value);
    if (parseInt(value) === 0) {
      setCustomCreditsError('Credits must be greater than 0');
    } else {
      setCustomCreditsError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if (!policyAcknowledged) {
      setError('Please acknowledge the Privacy Policy and EULA before proceeding.');
      return;
    }

    if (isCustomPlan && parseInt(customCredits) === 0) {
      setCustomCreditsError('Credits must be greater than 0');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw error;
      }

      const isTestCard = paymentMethod.card.last4 === '4242';
      setIsTestAccount(isTestCard);

      await onSubmit({
        ...initialData,
        billingEmail,
        plan: isCustomPlan ? 'custom' : selectedPlan.value,
        customCredits: isCustomPlan ? parseInt(customCredits) : null,
        paymentMethodId: paymentMethod.id,
        isTestAccount: isTestCard,
      });
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message || 'An error occurred during signup');
    } finally {
      setIsLoading(false);
    }
  };

  const calculateCustomPlanPrice = (credits) => {
    return credits * 15; // Assuming $15 per credit
  };

  const filteredPlans = plans.filter(plan => plan.name !== "Pay-as-you-go");

  const planOptions = filteredPlans.map(p => ({
    value: p.id,
    label: (
      <div className="plan-option">
        <span className="plan-name">{p.name}</span>
        <span className="plan-price">${p.price}/{p.interval}</span>
        <span className="plan-credits">{p.creditsPerMonth} credits/month</span>
      </div>
    )
  }));

  planOptions.push({
    value: 'custom',
    label: <div className="plan-option"><span className="plan-name">Custom Plan</span></div>
  });

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption);
    setIsCustomPlan(selectedOption.value === 'custom');
  };

  return (
    <form onSubmit={handleSubmit} className="billing-info">
      <div className="form-group">
        <label htmlFor="billingEmail">Billing Email:</label>
        <input
          type="email"
          id="billingEmail"
          value={billingEmail}
          onChange={(e) => setBillingEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="plan">Plan:</label>
        <Select
          id="plan"
          options={planOptions}
          value={selectedPlan}
          onChange={handlePlanChange}
          placeholder="Select a plan"
          isSearchable={false}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: '8px',
              border: '1px solid #ccc',
              boxShadow: 'none',
              '&:hover': {
                border: '1px solid #999',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#f7a308' : 'white',
              color: state.isSelected ? 'white' : '#333',
              '&:hover': {
                backgroundColor: '#f0f0f0',
                color: '#333',
              },
            }),
          }}
        />
      </div>
      {isCustomPlan && (
        <div className="form-group">
          <label htmlFor="customCredits">Number of Credits per Month:</label>
          <input
            type="number"
            id="customCredits"
            value={customCredits}
            onChange={handleCustomCreditsChange}
            required
          />
          {customCreditsError && <p className="error-message">{customCreditsError}</p>}
          <p>Price: ${calculateCustomPlanPrice(customCredits)}/month</p>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="card-element">Credit or debit card</label>
        <div className="card-element">
          <CardElement />
        </div>
      </div>
      <div className="form-group">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={policyAcknowledged}
            onChange={(e) => setPolicyAcknowledged(e.target.checked)}
          />
          I have read and agree to the Privacy Policy and EULA
        </label>
      </div>
      <div className="mt-3 text-center">
        <Link to="/legal" className="policy-link">View Privacy Policy & EULA</Link>
      </div>
      {error && <div className="error-message">{error}</div>}
      <button type="submit" className="submit-button" disabled={isLoading || !policyAcknowledged}>
        {isLoading ? 'Processing...' : 'Complete Signup'}
      </button>
    </form>
  );
};

export default BillingForm;