// Assistant/Assistant.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Pusher from 'pusher-js';
import API_BASE_URL from '../../constants/apiConfig';
import './Assistant.css';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import InputArea from './InputArea';
import FileAttachments from './FileAttachments';
import { getConfig } from '../../constants/config';
import 'bootstrap/dist/css/bootstrap.min.css';

const Assistant = () => {
  const user = useSelector((state) => state.user.user);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [isInitialMessageProcessed, setIsInitialMessageProcessed] = useState(false);
  const [initialMessage, setInitialMessage] = useState(null);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  const [isInitialMessageSent, setIsInitialMessageSent] = useState(false);
  const messageListRef = useRef(null);
  const pusherRef = useRef(null);

  const handlePusherEvent = useCallback((data) => {
    setMessages(prevMessages => {
      const messagesWithoutLoading = prevMessages.filter((msg, idx) => 
        !(idx === prevMessages.length - 1 && msg.isLoading)
      );
      const newMessage = { text: data.message, isUser: false, isLoading: false };
      return [...messagesWithoutLoading, newMessage];
    });
    setDisplayedText('');
    setIsTyping(true);
    simulateTyping(data.message);
  }, []);

  const handleSendMessage = useCallback(async (messageText = inputText) => {
    
    // Ensure messageText is a string
    const message = typeof messageText === 'string' ? messageText : inputText;
    
    if ((message.trim() !== '' || selectedFiles.length > 0) && user) {
      const newUserMessage = { text: message, isUser: true, isLoading: false, attachments: selectedFiles };
      const newAssistantMessage = { text: '', isUser: false, isLoading: true, attachments: [] };
      
      setMessages(prevMessages => [...prevMessages, newUserMessage, newAssistantMessage]);
      setInputText('');
      setSelectedFiles([]);
  
      try {
        const formData = new FormData();
        formData.append('phone_number', user.phone);
        formData.append('message', message);
        selectedFiles.forEach((file) => formData.append('file', file));
  
        const response = await axios.post(`${API_BASE_URL}/api/messages`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        if (response.data.error) {
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages(prevMessages => prevMessages.slice(0, -1));
        setErrorMessage(error.response?.data?.error || 'Failed to send message. Please try again.');
      }
    }
  }, [inputText, selectedFiles, user]);

  const fetchMessageHistory = useCallback(async () => {
    if (user && user.phone) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/message_history`, {
          params: { phone_number: user.phone }
        });
        const historyMessages = response.data.messages.map(msg => ({
          text: msg.text,
          isUser: msg.isUser,
          timestamp: new Date(msg.timestamp)
        }));
        setMessages(prevMessages => {
          // Keep the initial message if it exists
          const initialMessages = prevMessages.filter(msg => !msg.timestamp);
          return [...historyMessages, ...initialMessages];
        });
      } catch (error) {
        console.error('Error fetching message history:', error);
        const errorMessage = error.response?.data?.error || 'Failed to load message history. Please refresh the page.';
        setErrorMessage(errorMessage);
      } finally {
        setIsHistoryLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchMessageHistory();

    const initializePusher = async () => {
      try {
        const config = await getConfig();
        const pusherKey = config.REACT_APP_PUSHER_KEY;
        
        if (!pusherKey) {
          console.error('Pusher key not found in config');
          return;
        }

        pusherRef.current = new Pusher(pusherKey, { cluster: 'us2' });
        const channel = pusherRef.current.subscribe(`COA-${user.phone.replace('+', '')}`);
        channel.bind('my-event', handlePusherEvent);
        console.log('Pusher initialized and subscribed');
      } catch (error) {
        console.error('Failed to initialize Pusher:', error);
      }
    };

    initializePusher();

    return () => {
      if (pusherRef.current) {
        pusherRef.current.unsubscribe(`COA-${user.phone.replace('+', '')}`);
        pusherRef.current.disconnect();
        console.log('Pusher disconnected');
      }
    };
  }, [user.phone, handlePusherEvent, fetchMessageHistory]);

  useEffect(() => {
    if (!isInitialMessageProcessed) {
      const urlParams = new URLSearchParams(window.location.search);
      const message = urlParams.get('message');
      if (message) {
        setInitialMessage(decodeURIComponent(message));
      }
      setIsInitialMessageProcessed(true);
    }
  }, [isInitialMessageProcessed]);

  useEffect(() => {
    if (initialMessage && isInitialMessageProcessed && !isInitialMessageSent) {
      handleSendMessage(initialMessage);
      setIsInitialMessageSent(true);
      setInitialMessage(null);
    }
  }, [initialMessage, isInitialMessageProcessed, isInitialMessageSent, handleSendMessage]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages, displayedText]);

  const simulateTyping = (text, speed = 10) => {
    let i = 0;
    setIsTyping(true);
    const interval = setInterval(() => {
      if (i < text.length) {
        setDisplayedText(prev => text.substring(0, i + 1));
        i++;
      } else {
        clearInterval(interval);
        setIsTyping(false);
        setMessages(prevMessages => 
          prevMessages.map((msg, idx) => 
            idx === prevMessages.length - 1 ? { ...msg, text: text } : msg
          )
        );
      }
    }, speed);
  };

  const handleInputChange = (e) => setInputText(e.target.value);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    const invalidFiles = files.filter((file) => !validFileTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      setErrorMessage('Only PDF, JPEG, and PNG files are allowed.');
    } else {
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
      setErrorMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const clearSelectedFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="container mt-5 coa-assistant-root">
      <div className="card shadow">
        <div className="card-body assistant-container">
          <ChatHeader />
          <div className="chat-content">
            <MessageList
              messages={messages}
              isTyping={isTyping}
              displayedText={displayedText}
              messageListRef={messageListRef}
              isHistoryLoading={isHistoryLoading}
            />
          </div>
          <div className="chat-footer">
            <div className="input-wrapper">
              <FileAttachments
                selectedFiles={selectedFiles}
                clearSelectedFile={clearSelectedFile}
              />
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <InputArea
                inputText={inputText}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                handleFileChange={handleFileChange}
                handleSendMessage={handleSendMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assistant;