// useFilteredChangeOrderMetrics.js
import { useState, useMemo } from 'react';

const useFilteredChangeOrderMetrics = (allChangeOrders) => {
  const [timeRange, setTimeRange] = useState('all');

  // Filter the change orders based on the selected time range
  const filteredRegions = useMemo(() => {
    if (timeRange === 'all') return allChangeOrders;

    const filtered = {};
    Object.entries(allChangeOrders).forEach(([region, orders]) => {
      filtered[region] = orders.filter(order => {
        const orderDate = order.createdAt ? new Date(order.createdAt) : null;
        if (!orderDate) return false;
        const cutoffDate = new Date();
        cutoffDate.setDate(cutoffDate.getDate() - parseInt(timeRange));
        return orderDate >= cutoffDate;
      });
    });
    return filtered;
  }, [allChangeOrders, timeRange]);

  // Compute metrics for filtered change orders
  const filteredChangeOrdersMetrics = useMemo(() => {
    const metrics = {
      Unexecuted: { count: 0, totalValue: 0 },
      Executed: { count: 0, totalValue: 0 },
      Cancelled: { count: 0, totalValue: 0 },
      Total: { count: 0, totalValue: 0 }
    };
  
    const processedCorNumbers = new Set();
  
    Object.values(filteredRegions).forEach(orders => {
      orders.forEach(order => {
        // Skip orders that should be grouped as Incomplete
        if (!processedCorNumbers.has(order.corNumber) && 
            !['T&M Approved', 'Adjustment Needed', 'Incomplete', 'Awaiting Superintendent Approval'].includes(order.status)) {
          const value = parseFloat(order.amount) || 0;
    
          processedCorNumbers.add(order.corNumber);
    
          if (metrics[order.status]) {
            metrics[order.status].count++;
            metrics[order.status].totalValue += value;
            
            // Update Total as we go
            metrics.Total.count++;
            metrics.Total.totalValue += value;
          }
        }
      });
    });
  
    return metrics;
  }, [filteredRegions]);

  // Prepare pie chart data based on the computed metrics
  const pieChartData = useMemo(() => {
    if (filteredChangeOrdersMetrics.Total.count === 0) {
      return [
        { name: 'Unexecuted', value: 1, count: 0, totalValue: '0.00' },
        { name: 'Executed', value: 1, count: 0, totalValue: '0.00' },
        { name: 'Cancelled', value: 1, count: 0, totalValue: '0.00' },
      ];
    }

    return Object.entries(filteredChangeOrdersMetrics)
      .filter(([key]) => key !== 'Total')
      .map(([name, { count, totalValue }]) => ({
        name,
        value: count,
        count: count,
        totalValue: totalValue.toFixed(2),
      }));
  }, [filteredChangeOrdersMetrics]);

  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange);
  };

  return {
    timeRange,
    filteredChangeOrdersMetrics,
    pieChartData,
    handleTimeRangeChange,
    filteredRegions,
  };
};

export default useFilteredChangeOrderMetrics;