import React from 'react';
import LoadingDots from './LoadingDots';
import assistantLogo from '../../assets/images/Logo120x120.png';

const Message = ({ message, isTyping, displayedText, isLast }) => (
  <div className={`message ${message.isUser ? 'user-message' : 'assistant-message'}`}>
    {!message.isUser && (
      <div className="profile-image">
        <img src={assistantLogo} alt="Assistant" />
      </div>
    )}
    <div className="message-content">
      <pre>
        {message.isLoading ? (
          <LoadingDots />
        ) : isLast && !message.isUser && isTyping ? (
          displayedText
        ) : (
          message.text
        )}
      </pre>
      {message.attachments && message.attachments.length > 0 && (
        <div className="attachments-preview">
          {message.attachments.map((file, idx) => (
            <div key={idx} className="attachment-preview">
              <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default Message;