import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Select from 'react-select';
import API_BASE_URL from '../../constants/apiConfig';
import './UserSignup.css';
import './BillingForm';

const STRIPE_PUBLISHABLE_KEY = 'pk_live_51Ohwg7BcQcTjfkgqKbk7HoF8mvVyhV9s7tat0GE7IyaUltMwHg9IsDdswqvdqBGrSOxDh9Kv3a0r7lGWuKpU9FN400b7gH20Bu';
const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51Ohwg7BcQcTjfkgqy6K2VMS1OlrCB5AtSX60bcGaQ6v0W3nGW2o3tVj4DpgoipaZqFJIJEmNaxpTNjt4kGuXU5jR009ohB8sPY';

const BillingForm = ({ onSubmit, plans, setIsTestAccount, initialData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [billingEmail, setBillingEmail] = useState(initialData.email || '');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isCustomPlan, setIsCustomPlan] = useState(false);
  const [customCredits, setCustomCredits] = useState('');
  const [customCreditsError, setCustomCreditsError] = useState('');
  const [policyAcknowledged, setPolicyAcknowledged] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomCreditsChange = (e) => {
    const value = e.target.value;
    setCustomCredits(value);
    if (parseInt(value) === 0) {
      setCustomCreditsError('Credits must be greater than 0');
    } else {
      setCustomCreditsError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if (!policyAcknowledged) {
      setError('Please acknowledge the Privacy Policy and EULA before proceeding.');
      return;
    }

    if (isCustomPlan && parseInt(customCredits) === 0) {
      setCustomCreditsError('Credits must be greater than 0');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw error;
      }

      const isTestCard = paymentMethod.card.last4 === '4242';
      setIsTestAccount(isTestCard);

      await onSubmit({
        ...initialData,
        billingEmail,
        plan: isCustomPlan ? 'custom' : selectedPlan.value,
        customCredits: isCustomPlan ? parseInt(customCredits) : null,
        paymentMethodId: paymentMethod.id,
        isTestAccount: isTestCard,
      });
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message || 'An error occurred during signup');
    } finally {
      setIsLoading(false);
    }
  };

  const calculateCustomPlanPrice = (credits) => {
    return credits * 15; // Assuming $15 per credit
  };

  const filteredPlans = plans.filter(plan => plan.name !== "Pay-as-you-go");

  const planOptions = filteredPlans.map(p => ({
    value: p.id,
    label: (
      <div className="plan-option">
        <span className="plan-name">{p.name}</span>
        <span className="plan-price">${p.price}/{p.interval}</span>
        <span className="plan-credits">{p.creditsPerMonth} credits/month</span>
      </div>
    )
  }));

  planOptions.push({
    value: 'custom',
    label: <div className="plan-option"><span className="plan-name">Custom Plan</span></div>
  });

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption);
    setIsCustomPlan(selectedOption.value === 'custom');
  };

  return (
    <form onSubmit={handleSubmit} className="billing-info">
      <div className="form-group">
        <label htmlFor="billingEmail">Billing Email:</label>
        <input
          type="email"
          id="billingEmail"
          value={billingEmail}
          onChange={(e) => setBillingEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="plan">Plan:</label>
        <Select
          id="plan"
          options={planOptions}
          value={selectedPlan}
          onChange={handlePlanChange}
          placeholder="Select a plan"
          isSearchable={false}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: '8px',
              border: '1px solid #ccc',
              boxShadow: 'none',
              '&:hover': {
                border: '1px solid #999',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#f7a308' : 'white',
              color: state.isSelected ? 'white' : '#333',
              '&:hover': {
                backgroundColor: '#f0f0f0',
                color: '#333',
              },
            }),
          }}
        />
      </div>
      {isCustomPlan && (
        <div className="form-group">
          <label htmlFor="customCredits">Number of Credits per Month:</label>
          <input
            type="number"
            id="customCredits"
            value={customCredits}
            onChange={handleCustomCreditsChange}
            required
          />
          {customCreditsError && <p className="error-message">{customCreditsError}</p>}
          <p>Price: ${calculateCustomPlanPrice(customCredits)}/month</p>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="card-element">Credit or debit card</label>
        <div className="card-element">
          <CardElement />
        </div>
      </div>
      <div className="form-group">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={policyAcknowledged}
            onChange={(e) => setPolicyAcknowledged(e.target.checked)}
          />
          I have read and agree to the Privacy Policy and EULA
        </label>
      </div>
      <div className="mt-3 text-center">
        <Link to="/legal" className="policy-link">View Privacy Policy & EULA</Link>
      </div>
      {error && <div className="error-message">{error}</div>}
      <button type="submit" className="submit-button" disabled={isLoading || !policyAcknowledged}>
        {isLoading ? 'Processing...' : 'Complete Signup'}
      </button>
    </form>
  );
};

const UserSignup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isTestAccount, setIsTestAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPlans = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user-signup-plans?isTestAccount=${isTestAccount}`);
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPlans(data);
    } catch (error) {
      console.error('Error fetching plans:', error);
      setError(`Failed to load plans: ${error.message}`);
    }
  }, [isTestAccount]);

  useEffect(() => {
    const stripeKey = isTestAccount ? STRIPE_TEST_PUBLISHABLE_KEY : STRIPE_PUBLISHABLE_KEY;
    setStripePromise(loadStripe(stripeKey));
    fetchPlans();
  }, [isTestAccount, fetchPlans]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'companyName') {
      setIsTestAccount(value.toLowerCase().includes('testaccount123'));
    }
  };

  const formatPhoneNumberForDisplay = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedNumber = formatPhoneNumberForDisplay(e.target.value);
    setFormData(prevState => ({
      ...prevState,
      phoneNumber: formattedNumber
    }));
  };

  const handleInitialSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/user-signup/initial`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          isTestAccount: isTestAccount,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Initial signup failed');
      }

      setShowBillingForm(true);
    } catch (err) {
      console.error('Error in handleInitialSubmit:', err);
      setError('Failed to save initial data: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinalSubmit = async (billingData) => {
    setError('');
    setSuccessMessage('');
    setIsLoading(true);
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/user-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...billingData,
          isTestAccount: isTestAccount,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Signup failed');
      }
  
      await response.json(); // Consume the response
      setSuccessMessage('Registration successful! Redirecting to login...');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      console.error('Error in handleFinalSubmit:', err);
      setError('Failed to register: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-signup-root">
      <Link to="/" className="btn btn-outline-secondary back-button">
        &larr; Back
      </Link>
      <div className="user-signup-container">
        <h2>Sign Up {isTestAccount ? '(Test Account)' : ''}</h2>
        {!showBillingForm ? (
          <form onSubmit={handleInitialSubmit} className="user-signup-form">
            <div className="form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                placeholder="(123) 456-7890"
                required
              />
            </div>
            <button type="submit" className="submit-button" disabled={isLoading}>
              {isLoading ? 'Processing...' : 'Continue'}
            </button>
          </form>
        ) : (
          <div className="billing-section">
            <Elements stripe={stripePromise}>
              <BillingForm 
                onSubmit={handleFinalSubmit} 
                plans={plans} 
                setIsTestAccount={setIsTestAccount}
                initialData={formData}
              />
            </Elements>
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <p className="login-link">
          Already have an account? <span onClick={() => navigate('/login')}>Log in</span>
        </p>
      </div>
    </div>
  );
};

export default UserSignup;