import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { clearDeveloperImpersonating, userLoggedOut } from '../../redux/actions/userActions';
import API_BASE_URL from '../../constants/apiConfig';

const DevReturnButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDeveloperImpersonating = useSelector(state => state.user.isDeveloperImpersonating);

  const handleReturnToDev = async () => {
    try {
      await axios.post(`${API_BASE_URL}/dev/end-impersonation`);
      dispatch(clearDeveloperImpersonating());
      dispatch(userLoggedOut());
      navigate('/dev-dashboard');
    } catch (error) {
      console.error('Error ending impersonation:', error);
    }
  };

  if (!isDeveloperImpersonating) {
    return null;
  }

  return (
    <button className="btn btn-warning" onClick={handleReturnToDev}>
      Return to Dev Dashboard
    </button>
  );
};

export default DevReturnButton;