// src/modules/Analytics/Components/RouterAnalytics/RouterPerformanceGraph.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { getChartOptions, getChartData } from './ChartConfig';
import { createTimeBuckets, calculateGapData } from '../../utils/dataProcessing';
import { RouterStats } from './RouterStats';
import { RequestTable } from './RequestTable';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export const MessagesSection = ({ checkpointStats }) => {
  return (
    <div className="row mb-4">
      <div className="col-lg-12">
        <div className="dashboard-card h-100">
          <h3 className="card-title">Messages Sent by Assistant</h3>
          <div className="card-content">
            <div className="mt-3">
              <h2>{checkpointStats?.router?.count || 0}</h2>
              <p className="text-muted">Total messages sent by the assistant</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RouterPerformanceGraph = ({
  timeSeriesData,
  selectedRange,
  individualRequests,
  setShowRequestTable,
  onRequestClick
}) => {
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    // Capture the ref value inside the effect
    const chart = chartRef.current;
    
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  const bucketedData = createTimeBuckets(timeSeriesData, selectedRange);
  const mainData = bucketedData.map(point => point.avg_time); 
  const gapData = calculateGapData(mainData);

  const options = getChartOptions(
    selectedRange,
    bucketedData,
    setShowRequestTable,
    onRequestClick
  );

  const data = getChartData(
    bucketedData,
    individualRequests,
    gapData
  );

  return (
    <div className="mt-4" style={{ height: '400px' }}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export const RouterAnalyticsSection = ({
  checkpointStats,
  timeSeriesData,
  records,
  selectedRange,
  showRequestTable,
  setShowRequestTable,
  highlightedRequestId,
  setHighlightedRequestId
}) => {
  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="dashboard-card">
          <h3 className="card-title">Router Performance Statistics</h3>
          <div className="card-content">
            <RouterStats checkpointStats={checkpointStats} />
            
            {checkpointStats?.router && timeSeriesData && (
              <>
                <RouterPerformanceGraph 
                  timeSeriesData={timeSeriesData}
                  selectedRange={selectedRange}
                  individualRequests={records}
                  setShowRequestTable={setShowRequestTable}
                  onRequestClick={setHighlightedRequestId}
                />
                <div className="mt-4">
                  <h4>Performance Insights</h4>
                  <div className="performance-insights">
                    <div className="alert alert-info">
                      <strong>router</strong> has an average processing time 
                      of {checkpointStats.router.avg_time.toFixed(3)}s (min: {checkpointStats.router.min_time.toFixed(3)}s, 
                      max: {checkpointStats.router.max_time.toFixed(3)}s) across {checkpointStats.router.count} calls
                    </div>
                  </div>
                </div>
                {showRequestTable && (
                  <RequestTable 
                    requests={records}
                    highlightedRequestId={highlightedRequestId} 
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};