// src/modules/Analytics/utils/dateFormatters.js
export const formatTimestamp = (timestamp, format = 'default') => {
    const date = new Date(timestamp);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   
    switch (format) {
      case 'full':
        return date.toLocaleString('en-US', {
          timeZone: timezone,
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        
      case '1h':
        return date.toLocaleTimeString('en-US', {
          timeZone: timezone,
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
        
      case '1d':
        return date.toLocaleTimeString('en-US', {
          timeZone: timezone,
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
        
      case '1w':
      case '1m':
        return date.toLocaleDateString('en-US', {
          timeZone: timezone,
          month: 'short',
          day: 'numeric'
        });
        
      case '1y':
        return date.toLocaleDateString('en-US', {
          timeZone: timezone,
          month: 'short',
          year: 'numeric'
        });
        
      default:
        return date.toLocaleString('en-US', {
          timeZone: timezone
        });
    }
   };