import React from 'react';
import './Attachments.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Attachments = ({ attachments, onManageAttachments }) => {
  return (
    <div className="attachments-component">
      <div className="attachments-header">
        <div className="detail-label">Attachments ({attachments.length || 0})</div>
        <button 
          className="btn btn-secondary btn-sm"
          onClick={onManageAttachments}
        >
          Manage Attachments
        </button>
      </div>
    </div>
  );
};

export default Attachments;