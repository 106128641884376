// IntegrationPage/IntegrationPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../../constants/apiConfig';
import './IntegrationPage.css';
import { FaPlug } from 'react-icons/fa';
import QuickBooksLogo from '../../assets/images/QB-Integration-Logo.png';

const TRUSTED_ORIGIN = 'https://appcenter.intuit.com/connect/oauth2';

const IntegrationPage = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const [syncMessage, setSyncMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const customerId = useSelector((state) => state.user.user?.customer_id);

    const checkQuickBooksConnection = useCallback(async () => {
        if (!customerId) return false;

        try {
            const response = await fetch(`${API_BASE_URL}/quickbooks/status?customer_id=${encodeURIComponent(customerId)}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch status. Status: ${response.status}`);
            }
            const data = await response.json();
            setIsConnected(Boolean(data.isConnected));
            setSyncMessage(data.syncMessage || '');
            return Boolean(data.isConnected);
        } catch (err) {
            console.error('Error checking QuickBooks connection:', err);
            setError('Unable to determine QuickBooks sync status. Please try again later.');
            setSyncMessage('Unable to determine QuickBooks sync status.');
            return false;
        }
    }, [customerId]);

    useEffect(() => {
        checkQuickBooksConnection();
    }, [checkQuickBooksConnection]);

    const handleQuickBooksConnect = async () => {
        setError(null);
        setStatusMessage('');

        if (isConnected) {
            // Disconnect from QuickBooks
            try {
                const response = await fetch(`${API_BASE_URL}/quickbooks/disconnect`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({ customer_id: customerId }),
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`Failed to disconnect. Status: ${response.status}`);
                }
                const data = await response.json();
                if (data.success) {
                    setIsConnected(false);
                    setSyncMessage('Your QuickBooks account is not synced. Please connect your account.');
                    setStatusMessage('Successfully disconnected from QuickBooks.');
                } else {
                    throw new Error(data.error || 'Failed to disconnect from QuickBooks.');
                }
            } catch (err) {
                console.error('Error disconnecting from QuickBooks:', err);
                setError('Failed to disconnect. Please try again later.');
            }
        } else {
            // Connect to QuickBooks
            try {
                const response = await fetch(`${API_BASE_URL}/quickbooks/connect?customer_id=${encodeURIComponent(customerId)}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error(`Failed to initiate connection. Status: ${response.status}`);
                }
                const data = await response.json();
                if (data.authUrl) {
                    const authWindow = window.open(data.authUrl, '_blank', 'width=600,height=600,noopener,noreferrer');

                    if (!authWindow) {
                        throw new Error('Unable to open authentication window. Please disable popup blockers and try again.');
                    }

                    const handleMessage = async (event) => {
                        if (event.origin !== TRUSTED_ORIGIN) {
                            console.warn(`Untrusted origin: ${event.origin}`);
                            return;
                        }

                        if (event.data === 'quickbooks_connected') {
                            authWindow.close();
                            await checkQuickBooksConnection();
                            setStatusMessage('Successfully connected to QuickBooks.');
                        } else if (event.data === 'quickbooks_error') {
                            setError('Connection to QuickBooks failed. Please try again.');
                        }
                    };

                    window.addEventListener('message', handleMessage);

                    const pollConnection = setInterval(async () => {
                        if (authWindow.closed) {
                            clearInterval(pollConnection);
                            window.removeEventListener('message', handleMessage);
                            const connectionStatus = await checkQuickBooksConnection();
                            if (!connectionStatus) {
                                setError('Connection to QuickBooks was cancelled. Please try again.');
                            }
                        }
                    }, 1000);
                } else {
                    throw new Error(data.error || 'Failed to obtain QuickBooks authorization URL.');
                }
            } catch (err) {
                console.error('Error connecting to QuickBooks:', err);
                setError('Failed to connect. Please try again later.');
            }
        }
    };

    return (
        <div className="integration-page">
            <header className="integration-header">
                <h1>Integrations</h1>
                <p>Connect your Bridgepoint account with your accounting software.</p>
            </header>
            <div className="integration-list">
                <div className="integration-item quickbooks-integration">
                    <img src={QuickBooksLogo} alt="QuickBooks Logo" className="integration-icon" />
                    <p>
                        Sync your account with QuickBooks to automatically create invoices for executed change order requests.
                    </p>
                    <p className="sync-message">{syncMessage}</p>
                    <button className="connect-button" onClick={handleQuickBooksConnect}>
                        <FaPlug />
                        {isConnected ? ' Disconnect from QuickBooks' : ' Connect to QuickBooks'}
                    </button>
                    {error && <p className="error-message" role="alert">{error}</p>}
                    {statusMessage && <p className="status-message">{statusMessage}</p>}
                </div>
            </div>
        </div>
    );
};

export default IntegrationPage;