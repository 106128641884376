// ChangeOrder.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import JobInfo from './JobInfo/JobInfo';
import EditableJobInfo from './JobInfo/EditableJobInfo';
import Categories from './Categories/Categories';
import EditableCategories from './Categories/EditableCategories';
import ScheduleExtension from './ScheduleExtension/ScheduleExtension';
import EditableScheduleExtension from './ScheduleExtension/EditableScheduleExtension';
import Totals from './Totals/Totals';
import EditButton from '../shared/EditButton';
import EditableTotals from './Totals/EditableTotals';
import { jobNameAndGC } from './utils';
import useDownloadPdf from './useDownloadPdf';
import ManageAttachmentsModal from './Attachments/ManageAttachmentsModal';
import './ChangeOrder.css';
import { updateEditValues } from './redux/changeOrderActions';
import Attachments from './Attachments/Attachments';

const MAX_DESCRIPTION_LENGTH = 255;

const ChangeOrder = ({ user, initialCorNumber, loading, error, changeOrderDetails, jobOptions, isEditable, onEdit, onSave, onCancel, isNewOrder }) => {
  const dispatch = useDispatch();
  const { downloadPdf, isLoading: isPdfLoading } = useDownloadPdf();
  const [showManageAttachments, setShowManageAttachments] = useState(false);
  const [isDescriptionTooLong, setIsDescriptionTooLong] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const validateJobInfo = useRef(null);
  const { editValues } = useSelector((state) => state.changeOrder);
  const [descriptionHeight, setDescriptionHeight] = useState('auto');
  const descriptionRef = useRef(null);

  const fetchAttachments = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/change-order-attachments`, {
        params: {
          customer_id: user.customer_id,
          cor_number: initialCorNumber,
        },
      });
      setAttachments(response.data);
    } catch (error) {
      console.error('Error fetching attachments:', error);
    }
  }, [user.customer_id, initialCorNumber]);

  useEffect(() => {
    if (descriptionRef.current) {
      setDescriptionHeight(`${descriptionRef.current.scrollHeight}px`);
    }
  }, [editValues.desc]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }  

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!changeOrderDetails) {
    return <div>No change order details found.</div>;
  }

  const { jobName, gcName } = jobNameAndGC(changeOrderDetails?.bigDict, jobOptions);

  const jobDetails = {
    jobName,
    gcName,
    date: new Date().toLocaleDateString(),
  };

  const handleDownloadPdf = () => {
    if (user && user.customer_id && initialCorNumber) {
      downloadPdf({
        type: 'changeOrder',
        user: user,
        cor_number: initialCorNumber,
        change_orders_dict: null,
      });
    } else {
      console.error('Missing user/customer_id or cor_number for PDF download.');
    }
  };

  const handleManageAttachments = () => {
    setShowManageAttachments(true);
  };

  const handleValidationChange = (validationFunc) => {
    validateJobInfo.current = validationFunc;
  };

  const handleEdit = () => {
    onEdit();
  };

  const handleSave = () => {
    if (validateJobInfo.current && validateJobInfo.current()) {
      onSave();
    } else {
      console.log('Validation failed. Please check the form.');
    }
  };

  const handleCancel = () => {
    onCancel();  // Just call the onCancel prop
  };

  const handleDescriptionChange = (newDescription) => {
    if (newDescription.length <= MAX_DESCRIPTION_LENGTH) {
      dispatch(updateEditValues({ ...editValues, desc: newDescription }));
      setIsDescriptionTooLong(false);
    } else {
      setIsDescriptionTooLong(true);
    }
  };

  return (
    <div className={`change-order-component ${isEditable ? 'editable-mode' : ''}`}>
      <div className="card custom-card">
        <div className="change-order-container">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h1 className="card-title">Change Order Request</h1>
            <div className="button-group">
              {!isEditable && (
                <button onClick={handleDownloadPdf} className="btn btn-primary me-2">
                  {isPdfLoading ? 'Downloading...' : 'Download PDF'}
                </button>
              )}
              <EditButton
                isEditable={isEditable}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            </div>
          </div>
          <div className="card-body">
            <div className="row job-info-customer-info-row">
              <div className="col-md-6">
                {isEditable ? (
                  <EditableJobInfo onValidationChange={handleValidationChange} />
                ) : (
                  <JobInfo job={jobDetails} />
                )}
              </div>
              <div className="col-md-6">
                <CustomerInfo />
              </div>
            </div>

            <div className="cor-details-section">
              <h3>COR Details</h3>
              
              {!isNewOrder && (
                <div className="detail-item">
                  <div className="detail-label">COR #: {initialCorNumber}</div>
                </div>
              )}
              
              <div className="detail-item">
                <Attachments 
                  attachments={attachments}
                  onManageAttachments={handleManageAttachments}
                />
              </div>

              {(isEditable || changeOrderDetails.bigDict.schedule_extension) && (
                <div className="detail-item">
                  {isEditable ? (
                    <EditableScheduleExtension />
                  ) : (
                    <ScheduleExtension />
                  )}
                </div>
              )}

              <div className="detail-item">
                <div className="detail-label">Description: </div>
                {isEditable ? (
                  <div className="description-container">
                    <textarea
                      ref={descriptionRef}
                      className="form-control"
                      value={editValues.desc || ''}
                      onChange={(e) => handleDescriptionChange(e.target.value)}
                      style={{ height: descriptionHeight }}
                      maxLength={MAX_DESCRIPTION_LENGTH}
                    />
                    {isDescriptionTooLong && (
                      <p className="text-danger">Description is too long (max 255 characters)</p>
                    )}
                    <p className="text-muted">
                      {editValues.desc ? editValues.desc.length : 0}/{MAX_DESCRIPTION_LENGTH} characters
                    </p>
                  </div>
                ) : (
                  <div>{changeOrderDetails.bigDict.desc}</div>
                )}
              </div>
            </div>

            {isEditable ? (
              <>
                <EditableCategories />
                <EditableTotals />
              </>
            ) : (
              <>
                <Categories />
                <Totals />
              </>
            )}
          </div>
        </div>
      </div>
      <ManageAttachmentsModal 
        show={showManageAttachments} 
        onHide={() => setShowManageAttachments(false)} 
        user={user} 
        initialCorNumber={initialCorNumber}
        attachments={attachments}
        onAttachmentsChange={fetchAttachments}
      />
    </div>
  );
};

export default ChangeOrder;