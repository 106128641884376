import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../../constants/apiConfig';
import EmployeeTable from './UsersTable';
import EditableEmployeeTable from './EditableUsersTable';

const UsersPage = () => {
  const customerId = useSelector((state) => state.user.user?.customer_id);
  const [isEditable, setIsEditable] = useState(false);
  const [initialEmployees, setInitialEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [newEmployees, setNewEmployees] = useState([]);
  const [rowErrors, setRowErrors] = useState({}); // Store errors by row
  const [isLoading, setIsLoading] = useState(false);

  const validateName = (name) => {
    return name.trim().length > 0;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?1?\s*\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  const validateRow = (employee) => {
    const errors = [];
    
    if (!validateName(employee.first_name)) {
      errors.push('First name is required');
    }
    if (!validateName(employee.last_name)) {
      errors.push('Last name is required');
    }
    if (!validatePhoneNumber(employee.phone_number)) {
      errors.push('Please enter a valid phone number');
    }
    if (employee.estimator && (!employee.email || !validateEmail(employee.email))) {
      errors.push('Valid email is required for estimators');
    }

    return errors;
  };

  const validateAllRows = () => {
    const newErrors = {};
    let hasErrors = false;

    // Validate existing employees
    employees.forEach(employee => {
      const rowErrors = validateRow(employee);
      if (rowErrors.length > 0) {
        newErrors[employee.phone_number] = rowErrors;
        hasErrors = true;
      }
    });

    // Validate new employees
    newEmployees.forEach((employee, index) => {
      const rowErrors = validateRow(employee);
      if (rowErrors.length > 0) {
        newErrors[`new-${index}`] = rowErrors;
        hasErrors = true;
      }
    });

    setRowErrors(newErrors);
    return !hasErrors;
  };

  const fetchEmployees = useCallback(async () => {
    if (customerId) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/employees?customer_id=${customerId}`);
        const data = await response.json();
        setInitialEmployees(data);
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    }
  }, [customerId]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const enterEditMode = () => {
    setIsEditable(true);
    setRowErrors({});
  };

  const cancelEditMode = () => {
    setEmployees([...initialEmployees]);
    setNewEmployees([]);
    setIsEditable(false);
    setRowErrors({});
  };

  const saveEditMode = async () => {
    if (!validateAllRows()) {
      return;
    }
  
    setIsLoading(true); // Start loading
  
    try {
      // Update existing employees
      await Promise.all(
        employees.map((employee) =>
          fetch(`${API_BASE_URL}/api/employees/${employee.phone_number}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...employee,
              customer_id: customerId,
              last_co_estimator: employee.last_co_estimator,
              estimator: employee.estimator,
            }),
          })
        )
      );
  
      // Add new employees
      await Promise.all(
        newEmployees.map((employee) =>
          fetch(`${API_BASE_URL}/api/employees`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...employee, customer_id: customerId }),
          })
        )
      );
  
      // Delete removed employees
      const removedEmployees = initialEmployees.filter(
        (initialEmployee) =>
          !employees.some((employee) => employee.phone_number === initialEmployee.phone_number)
      );
      await Promise.all(
        removedEmployees.map((employee) =>
          fetch(`${API_BASE_URL}/api/employees/${employee.phone_number}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customer_id: customerId }),
          })
        )
      );
  
      // Fetch updated employee list
      await fetchEmployees();
  
      setNewEmployees([]);
      setIsEditable(false);
      setRowErrors({});
    } catch (error) {
      console.error('Error saving employees:', error);
      setRowErrors(prev => ({
        ...prev,
        saveError: ['Failed to save changes. Please try again.']
      }));
    } finally {
      setIsLoading(false); // End loading regardless of outcome
    }
  };

  const addNewLine = () => {
    const newEmployee = {
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      language: 'English',
      estimator: false,
    };
    setNewEmployees([...newEmployees, newEmployee]);
  };

  const removeEmployee = (phoneNumber) => {
    setEmployees(employees.filter((employee) => employee.phone_number !== phoneNumber));
    setRowErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[phoneNumber];
      return newErrors;
    });
  };

  const handleEmployeeChange = (phoneNumber, field, value) => {
    setEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.phone_number === phoneNumber
          ? { 
              ...employee, 
              [field]: value,
              ...(field === 'estimator' && value ? { last_co_estimator: '' } : {}) 
            }
          : employee
      )
    );

    // Validate the row after change
    const employee = employees.find(emp => emp.phone_number === phoneNumber);
    if (employee) {
      const updatedEmployee = {
        ...employee,
        [field]: value
      };
      const errors = validateRow(updatedEmployee);
      setRowErrors(prev => ({
        ...prev,
        [phoneNumber]: errors.length > 0 ? errors : undefined
      }));
    }
  };

  return (
    <div className="users-page">
      {isLoading && <LoadingOverlay />}
      <div className="container mt-4">
        <div className="card shadow">
          <div className="card-body">
            <h1>Users</h1>
            {rowErrors.saveError && (
              <div className="alert alert-danger">
                {rowErrors.saveError.join(', ')}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                {isEditable ? (
                  <EditableEmployeeTable
                    employees={employees}
                    setEmployees={setEmployees}
                    newEmployees={newEmployees}
                    setNewEmployees={setNewEmployees}
                    removeEmployee={removeEmployee}
                    handleEmployeeChange={handleEmployeeChange}
                    validateName={validateName}
                    validatePhoneNumber={validatePhoneNumber}
                    validateEmail={validateEmail}
                    rowErrors={rowErrors}
                    setRowErrors={setRowErrors}
                  />
                ) : (
                  <EmployeeTable employees={employees} />
                )}
                {!isEditable && (
                  <button className="btn btn-primary mt-3" onClick={enterEditMode}>
                    Edit Users
                  </button>
                )}
                {isEditable && (
                  <div>
                    <button className="btn btn-secondary mt-3 me-2" onClick={addNewLine}>
                      Add New Line
                    </button>
                    <button className="btn btn-primary mt-3 me-2" onClick={saveEditMode}>
                      Save
                    </button>
                    <button className="btn btn-secondary mt-3" onClick={cancelEditMode}>
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;