// ChangeOrderComponents/ChangeOrderContainer.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchChangeOrder, updateEditValues, saveChangeOrder } from './redux/changeOrderActions';
import ChangeOrder from './ChangeOrder';

const ChangeOrderContainer = ({ isNewOrder = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const initialCorNumber = isNewOrder ? null : params.corNumber;
  const user = useSelector((state) => state.user.user);
  const { loading, error, changeOrderDetails, jobOptions, editValues } = useSelector((state) => state.changeOrder);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (user && user.customer_id) {
      dispatch(fetchChangeOrder(user, initialCorNumber, isNewOrder));
    } else {
      console.log('User object is missing or does not have customer_id');
    }
  }, [dispatch, initialCorNumber, isNewOrder, user]);

  useEffect(() => {
    if (isNewOrder && !loading && changeOrderDetails && changeOrderDetails.bigDict && !isEditable) {
      dispatch(updateEditValues(changeOrderDetails.bigDict));
      setIsEditable(true);
    }
  }, [changeOrderDetails, isNewOrder, dispatch, loading, isEditable]);

  useEffect(() => {
    if (!isEditable && !isNewOrder) {
      dispatch(fetchChangeOrder(user, initialCorNumber, isNewOrder));
    }
  }, [isEditable, isNewOrder, dispatch, user, initialCorNumber]);

  useEffect(() => {
    return () => {
      if (isEditable) {
        setIsEditable(false);
      }
    };
  }, [isEditable]);

  const handleEdit = () => {
    dispatch(updateEditValues(changeOrderDetails.bigDict));
    setIsEditable(true);
  };

  const handleSave = () => {
    dispatch(saveChangeOrder(user, initialCorNumber, editValues, isNewOrder))
      .then((changeOrderDetails) => {
        const updatedCorNumber = changeOrderDetails.corNumber;
        setIsEditable(false);
        navigate(`/change-order/${updatedCorNumber}`);
      })
      .catch((error) => {
        console.error('Error saving change order:', error);
        // Handle the error state in the component
      });
  };

  const handleCancel = () => {
    if (isNewOrder) {
      navigate('/dashboard');
    } else {
      setIsEditable(false);
    }
  };

  return (
    <ChangeOrder
      user={user}
      initialCorNumber={initialCorNumber}
      loading={loading}
      error={error}
      changeOrderDetails={changeOrderDetails}
      jobOptions={jobOptions}
      isEditable={isEditable}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      isNewOrder={isNewOrder}
    />
  );
};

export default ChangeOrderContainer;