// CheckoutFlow.js

import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import API_BASE_URL from '../../constants/apiConfig';
import BasePaymentForm from './BasePaymentForm';

const SubscriptionCheckoutForm = ({ onSubscriptionComplete, onBack, selectedPlan, customerId }) => {
  const handleSubmit = async (stripe, elements, email) => {
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/billing`,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
      redirect: 'if_required',
    });

    if (error) {
      throw new Error(error.message);
    }

    if (setupIntent.status === 'succeeded') {
      try {
        const response = await fetch(`${API_BASE_URL}/api/subscription-checkout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: customerId,
            plan_id: selectedPlan.id,
            payment_method_id: setupIntent.payment_method,
            email: email,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to process subscription');
        }

        const result = await response.json();

        if (result.requires_action) {
          const { error: confirmationError } = await stripe.confirmCardPayment(result.payment_intent_client_secret);
          if (confirmationError) {
            throw new Error(confirmationError.message);
          }
        }

        onSubscriptionComplete(result);
      } catch (error) {
        throw new Error('Failed to process subscription. Please try again.');
      }
    } else {
      throw new Error('Unexpected setup intent status. Please try again.');
    }
  };

  return (
    <BasePaymentForm 
      onSubmit={handleSubmit}
      onCancel={onBack}
      submitButtonText="Confirm Subscription"
      cancelButtonText="Back"
    />
  );
};

const CheckoutFlow = ({ onComplete, customerId }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/available-plans?customer_id=${customerId}`);
        if (response.ok) {
          const data = await response.json();
          setPlans(data);
        } else {
          throw new Error('Failed to fetch plans');
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
        setError('Failed to load plans. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, [customerId]);

  const handleSelectPlan = async (plan) => {
    setSelectedPlan(plan);
    try {
      const response = await fetch(`${API_BASE_URL}/api/create-payment-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          customer_id: customerId, 
          session_type: 'setup',
          plan_id: plan.id
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setClientSecret(data.clientSecret);
        setStripePromise(loadStripe(data.publishableKey));
      } else {
        throw new Error('Failed to create payment session');
      }
    } catch (error) {
      console.error('Error creating payment session:', error);
      setError('Failed to initialize checkout. Please try again.');
    }
  };

  const handleBackToPlanSelection = () => {
    setSelectedPlan(null);
    setClientSecret(null);
    setStripePromise(null);
    setError(null);
  };

  if (isLoading) {
    return <div>Loading plans...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  if (!selectedPlan) {
    return (
      <div>
        <h3>Select a Plan</h3>
        {plans.map((plan) => (
          <div key={plan.id} className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">{plan.name}</h5>
              <p className="card-text">{plan.description}</p>
              <p className="card-text">
                Price: ${plan.price}/{plan.name === "Pay-as-you-go" ? "credit" : plan.interval}
              </p>
              {plan.name !== "Pay-as-you-go" && (
                <p className="card-text">Credits: {plan.creditsPerMonth} per {plan.interval}</p>
              )}
              <button 
                onClick={() => handleSelectPlan(plan)}
                className="btn btn-primary"
              >
                Select Plan
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!clientSecret || !stripePromise) {
    return <div>Preparing checkout...</div>;
  }

  return (
    <div>
      <h3>Checkout for {selectedPlan.name}</h3>
      <p>Price: ${selectedPlan.price}/{selectedPlan.name === "Pay-as-you-go" ? "credit" : selectedPlan.interval}</p>
      {selectedPlan.name !== "Pay-as-you-go" && (
        <p>Credits: {selectedPlan.creditsPerMonth} per {selectedPlan.interval}</p>
      )}
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <SubscriptionCheckoutForm 
          onSubscriptionComplete={onComplete}
          onBack={handleBackToPlanSelection}
          selectedPlan={selectedPlan}
          customerId={customerId}
        />
      </Elements>
    </div>
  );
};

export default CheckoutFlow;