// src/modules/Analytics/Components/AnalyticsCounts.js
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAnalyticsData } from '../hooks/useAnalyticsData';
import { TimelineSection } from './RouterAnalytics/RouterStats';
import { MessagesSection, RouterAnalyticsSection } from './RouterAnalytics/RouterPerformanceGraph';

const EnvironmentSelector = ({ selectedEnvironment, setSelectedEnvironment }) => (
  <select
    className="form-select ms-3"
    style={{ width: 'auto' }}
    value={selectedEnvironment}
    onChange={(e) => setSelectedEnvironment(e.target.value)}
  >
    <option value="prod">Production</option>
    <option value="dev">Development</option>
    <option value="staging">Staging</option>
  </select>
);

const AnalyticsCounts = () => {
  const { 
    analyticsData, 
    checkpointData,
    isLoading,
    error,
    fetchAnalyticsData,
    selectedRange,
    setSelectedRange,
    selectedEnvironment,
    setSelectedEnvironment,
    showRequestTable,
    setShowRequestTable,
    highlightedRequestId,
    setHighlightedRequestId
  } = useAnalyticsData();

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (isLoading.initial) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" />
        <p>Loading analytics data...</p>
      </div>
    );
  }

  if (!analyticsData || !checkpointData) {
    return <div className="alert alert-info">No analytics data available</div>;
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-12 mb-4">
          <div className="dashboard-card">
            <div className="d-flex justify-content-between align-items-center">
              <TimelineSection 
                selectedRange={selectedRange}
                onRangeChange={setSelectedRange}
                onRefresh={() => fetchAnalyticsData(selectedRange)}
                isLoading={isLoading.refresh}
              />
              <EnvironmentSelector 
                selectedEnvironment={selectedEnvironment}
                setSelectedEnvironment={setSelectedEnvironment}
              />
            </div>
          </div>
        </div>
      </div>
      
      <MessagesSection 
        checkpointStats={checkpointData?.checkpoint_stats} 
      />
      
      <RouterAnalyticsSection
        checkpointStats={checkpointData?.checkpoint_stats}
        timeSeriesData={checkpointData?.time_series}
        records={checkpointData?.records}
        selectedRange={selectedRange}
        showRequestTable={showRequestTable}
        setShowRequestTable={setShowRequestTable}
        highlightedRequestId={highlightedRequestId}
        setHighlightedRequestId={setHighlightedRequestId}
      />
    </>
  );
};

export default AnalyticsCounts;