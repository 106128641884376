import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../constants/apiConfig';
import './JobsPage.css';

const JobsPage = () => {
  const user = useSelector((state) => state.user.user);
  const [activeJobs, setActiveJobs] = useState([]);
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [requireSuperintendentApproval, setRequireSuperintendentApproval] = useState(false);
  const [modalInfo, setModalInfo] = useState({ show: false, message: '' });
  const navigate = useNavigate();

  const fetchJobs = useCallback(async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_BASE_URL}/jobs-page`, {
          params: {
            customer_id: user.customer_id,
          },
        });
        const { jobs, requireSuperintendentApproval } = response.data;
        setActiveJobs(jobs.filter(job => job.status !== 'Archived'));
        setArchivedJobs(jobs.filter(job => job.status === 'Archived'));
        setRequireSuperintendentApproval(requireSuperintendentApproval);
        return jobs;
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    }
    return null;
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchJobs();
    }
  }, [user, fetchJobs]);

  const handleSelectJob = (jobName) => {
    const formattedJobName = jobName.replace(/\s+/g, '-');
    navigate(`/job/${formattedJobName}`);
  };

  const handleCreateNewJob = () => {
    const message = encodeURIComponent("Create a new job");
    navigate(`/assistant?message=${message}`);
  };

  const handleToggleJobArchive = async (jobId) => {
    try {
      await axios.post(`${API_BASE_URL}/job/toggle-archive`, {
        jobId: jobId,
        customerId: user.customer_id,
      });
      fetchJobs();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const unexecutedCount = error.response.data.unexecutedCount;
        setModalInfo({
          show: true,
          message: `This job has ${unexecutedCount} unexecuted change order(s). Please execute or delete them before archiving.`,
        });
      } else {
        console.error('Error toggling job archive status:', error);
      }
    }
  };

  const JobTable = ({ jobs, isActive }) => (
    <table className="job-table">
      <thead>
        <tr>
          <th>Job Name</th>
          <th>Status</th>
          {requireSuperintendentApproval && <th>Superintendent Approval Required</th>}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <tr key={job.id} onClick={() => handleSelectJob(job.jobName)}>
            <td>{job.jobName}</td>
            <td>{job.status}</td>
            {requireSuperintendentApproval && (
              <td>{job.superintendentApprovalRequired ? 'Yes' : 'No'}</td>
            )}
            <td>
              <button 
                className={`btn btn-sm ${isActive ? 'btn-warning' : 'btn-success'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleJobArchive(job.id);
                }}
              >
                {isActive ? 'Archive' : 'Unarchive'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const WarningModal = ({ show, message, onClose }) => (
    <>
      {show && (
        <div className="modal-backdrop show"></div>
      )}
      <div className={`modal ${show ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Warning</h5>
              <button type="button" className="close" onClick={onClose}>
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="container mt-5 jobs-page">
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="card-title">Active Jobs</h2>
            <button className="btn btn-primary" onClick={handleCreateNewJob}>Create New Job</button>
          </div>
          <JobTable jobs={activeJobs} isActive={true} />
          {activeJobs.length === 0 && <div>No active jobs to display.</div>}
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body">
          <h2 className="card-title mb-3">Archived Jobs</h2>
          <JobTable jobs={archivedJobs} isActive={false} />
          {archivedJobs.length === 0 && <div>No archived jobs to display.</div>}
        </div>
      </div>

      <WarningModal 
        show={modalInfo.show} 
        message={modalInfo.message} 
        onClose={() => setModalInfo({ show: false, message: '' })}
      />
    </div>
  );
};

export default JobsPage;