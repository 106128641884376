// src/modules/Analytics/utils/dataProcessing.js
export const createTimeBuckets = (timeSeriesData, selectedRange) => {
  if (!timeSeriesData || timeSeriesData.length === 0) {
    return [];
  }
 
  const roundToInterval = (timestamp) => {
    // Handle both Z-suffixed and non-Z timestamps
    const date = new Date(timestamp.endsWith('Z') ? timestamp : timestamp + 'Z');
    
    switch (selectedRange) {
      case '1h':
        date.setMinutes(Math.floor(date.getMinutes() / 5) * 5, 0, 0);
        break;
      case '1d':
        date.setMinutes(0, 0, 0);
        break;
      case '1w':
      case '1m':
        date.setHours(0, 0, 0, 0);
        break;
      case '1y':
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        break;
      default:
        date.setMinutes(0, 0, 0);
    }
    return date;
  };
 
  const buckets = new Map();
  
  timeSeriesData.forEach(point => {
    const roundedTime = roundToInterval(point.timestamp);
    const timeKey = roundedTime.getTime();
    
    if (!buckets.has(timeKey)) {
      buckets.set(timeKey, {
        timestamp: roundedTime,
        totalTime: 0,
        count: 0,
        dataPoints: 0
      });
    }
    
    const bucket = buckets.get(timeKey);
    bucket.totalTime += point.avg_time * point.data_points;
    bucket.count += point.data_points;
    bucket.dataPoints += point.data_points;
  });
 
  return Array.from(buckets.values())
    .map(bucket => ({
      timestamp: bucket.timestamp,
      avg_time: bucket.totalTime / bucket.count,
      dataPoints: bucket.dataPoints
    }))
    .sort((a, b) => a.timestamp - b.timestamp);
 };
   
   export const calculateGapData = (mainData) => {
    const gapData = [...mainData];
   
    for (let i = 0; i < gapData.length; i++) {
      if (gapData[i] === null) {
        let prevIndex = i - 1;
        let nextIndex = i + 1;
        
        while (nextIndex < gapData.length && gapData[nextIndex] === null) {
          nextIndex++;
        }
        
        if (prevIndex >= 0 && nextIndex < gapData.length && nextIndex - prevIndex <= 3) {
          const prevVal = gapData[prevIndex];
          const nextVal = gapData[nextIndex];
          if (prevVal !== null && nextVal !== null) {
            const step = (nextVal - prevVal) / (nextIndex - prevIndex);
            for (let j = prevIndex + 1; j < nextIndex; j++) {
              gapData[j] = prevVal + step * (j - prevIndex);
            }
          }
        }
      }
    }
   
    return gapData;
   };   