// src/modules/shared/EditButton.js
import React from 'react';

const EditButton = ({ isEditable, onEdit, onSave, onCancel, isDisabled }) => {
  return (
    <div>
      {isEditable ? (
        <>
          <button 
            className="btn btn-primary me-2" 
            onClick={onSave} 
            disabled={isDisabled}
          >
            Save Changes
          </button>
          <button className="btn btn-danger" onClick={onCancel}>
            Cancel
          </button>
        </>
      ) : (
        <button className="btn btn-secondary" onClick={onEdit}>
          Edit
        </button>
      )}
    </div>
  );
};

export default EditButton;