// Dashboard/ChangeOrdersTable.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useUpdateStatus from './useUpdateStatus';
import ConfirmationModal from './ConfirmationModal';
import './ChangeOrdersTable.css';
import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBell, faTrash } from '@fortawesome/free-solid-svg-icons';

const getStatusGroup = (status) => {
  const incompleteStatuses = [
    'Incomplete', 
    'T&M Approved', 
    'Adjustment Needed', 
    'Awaiting Superintendent Approval'
  ];
  
  if (incompleteStatuses.includes(status)) {
    return 'Incomplete';
  }
  return status;
};

const ChangeOrdersTable = ({ changeOrders: initialChangeOrders, excludeJobNames = false }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [deletedCorNumbers, setDeletedCorNumbers] = useState(new Set());
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCor, setHoveredCor] = useState(null);
  const [tempStatuses, setTempStatuses] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [showCancelButton, setShowCancelButton] = useState(true);
  const { updateStatus, deleteChangeOrder } = useUpdateStatus(user);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelCorNumber, setCancelCorNumber] = useState(null);
  const [cancelModalState, setCancelModalState] = useState('confirm');
  const [cancelModalMessage, setCancelModalMessage] = useState('');
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [reminderModalMessage, setReminderModalMessage] = useState('');

  const getFilteredOrders = () => {
    if (Array.isArray(initialChangeOrders)) {
      return initialChangeOrders.filter(order => !deletedCorNumbers.has(order.corNumber));
    } else {
      const filteredOrders = {};
      Object.entries(initialChangeOrders).forEach(([region, orders]) => {
        const filteredRegionOrders = orders.filter(order => !deletedCorNumbers.has(order.corNumber));
        if (filteredRegionOrders.length > 0) {
          filteredOrders[region] = filteredRegionOrders;
        }
      });
      return filteredOrders;
    }
  };

  const displayOrders = getFilteredOrders();


  const handleRemindSuperintendent = async (corNumber) => {
    if (user?.customer_id) {
      try {
        const response = await axios.post(`${API_BASE_URL}/remind-superintendent`, {
          customer_id: user.customer_id,
          cor_number: corNumber
        }, {
          withCredentials: true
        });
  
        if (response.data.message === 'Reminder sent successfully') {
          setReminderModalMessage('Reminder sent successfully');
        } else {
          setReminderModalMessage('Failed to send reminder');
        }
      } catch (error) {
        console.error('Error sending reminder:', error);
        setReminderModalMessage('Error sending reminder');
      } finally {
        setShowReminderModal(true);
      }
    }
  };

  const getStatusOptions = (currentStatus) => {
    switch (currentStatus) {
      case 'Unexecuted':
        return ['Unexecuted', 'Executed', 'Cancelled', 'Deleted'];
      case 'Executed':
        return ['Executed', 'Unexecuted', 'Cancelled', 'Deleted'];
      case 'Cancelled':
        return ['Cancelled', 'Unexecuted', 'Executed', 'Deleted'];
      case 'Deleted':
        return ['Deleted', 'Unexecuted', 'Executed', 'Cancelled'];
      default:
        return ['Deleted'];
    }
  };



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && Object.values(displayOrders).flat().length > 0 && isInitialLoad) {
      setExpandedOrder(Object.values(displayOrders).flat()[0].corNumber);
      setIsInitialLoad(false);
    }
  }, [isMobile, displayOrders, isInitialLoad]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openDropdown && !event.target.closest('.dropdown')) {
        setOpenDropdown(null);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openDropdown]);

  const getColumnConfig = (statusGroup) => {
    let config = {
      includedColumns: [],
      columnWidths: []
    };
  
    if (isMobile) {
      config.includedColumns = ['corNumber', 'description', 'status', 'amount'];
      config.columnWidths = ['25%', '25%', '25%', '25%'];
    } else {
      if (statusGroup === 'Incomplete') {
        config.includedColumns = ['complete', 'corNumber', 'description', 'status', 'amount'];
        config.columnWidths = ['8%', '6%', '48%', '24%', '14%'];
      } else if (statusGroup === 'Unexecuted') {
        config.includedColumns = ['daysOld', 'corNumber', 'description', 'status', 'amount'];
        config.columnWidths = ['8%', '6%', '54%', '16%', '16%'];
      } else {
        config.includedColumns = ['corNumber', 'description', 'status', 'amount'];
        config.columnWidths = ['6%', '60%', '17%', '17%'];
      }
  
      if (!excludeJobNames) {
        config.includedColumns.splice(3, 0, 'jobName');
        config.columnWidths = config.columnWidths.map(width => 
          `${parseFloat(width) * 0.85}%`
        );
        config.columnWidths.splice(3, 0, '15%');
      }
    }
  
    return config;
  };

  const handleCorSelection = (corNumber, isOldCor) => {
    if (isOldCor) {
      setShowModal(true);
      setModalMessage("Information unavailable. Change Order was created outside of assistant.");
      setConfirmButtonText("Close");
      setConfirmAction(() => () => setShowModal(false));
      setShowCancelButton(false);
    } else {
      navigate(`/change-order/${corNumber}`);
    }
  };

  const handleSelectJob = (jobName) => {
    const formattedJobName = jobName.replace(/\s+/g, '-');
    navigate(`/job/${formattedJobName}`);
  };

  const handleDelete = async (corNumber) => {
    setModalMessage('Are you sure you want to delete this change order? This action cannot be undone.');
    setConfirmButtonText('Delete');
    setShowCancelButton(true);
    setConfirmAction(() => async () => {
      try {
        await deleteChangeOrder(corNumber);
        
        // Add the corNumber to our deleted set
        setDeletedCorNumbers(prev => new Set([...prev, corNumber]));
        
        // Cleanup temp statuses
        setTempStatuses(prev => {
          const updated = { ...prev };
          delete updated[corNumber];
          return updated;
        });
        
        setShowModal(false);
      } catch (error) {
        console.error("Failed to delete change order:", error);
        setModalMessage("Failed to delete change order. Please try again.");
        setConfirmButtonText('OK');
        setShowCancelButton(false);
        setConfirmAction(() => () => setShowModal(false));
      }
    });
    setShowModal(true);
  };

  const handleStatusChange = (corNumber, newStatus, daysOld) => {
    let currentOrder = Array.isArray(displayOrders) 
      ? displayOrders.find(order => order.corNumber === corNumber)
      : Object.values(displayOrders).flat().find(order => order.corNumber === corNumber);
  
    let originalStatus = currentOrder?.status;
    
    setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: "Loading..." }));
  
    updateStatus('changeOrder', corNumber, newStatus)
      .then((response) => {  
        if (response.data.success) {
          setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: newStatus }));
  
          if (!response.data.quickbooks_sync) {
            setModalMessage(response.data.message);
            setConfirmButtonText('OK');
            setShowCancelButton(false);
            setConfirmAction(() => () => setShowModal(false));
            setShowModal(true);
          }
        } else {
          throw new Error(response.data.message || 'Failed to update status');
        }
      })
      .catch((error) => {
        console.error("Failed to update status:", error);
        setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: originalStatus }));
        setModalMessage("Failed to update status. Please try again.");
        setConfirmButtonText('OK');
        setShowCancelButton(false);
        setConfirmAction(() => () => setShowModal(false));
        setShowModal(true);
      });
  };
  
  const handleMouseEnter = (corNumber) => {
    setHoveredRow(corNumber);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleMouseEnterCor = (corNumber) => {
    setHoveredCor(corNumber);
  };

  const handleMouseLeaveCor = () => {
    setHoveredCor(null);
  };

  const confirmCancellation = async () => {
    if (cancelCorNumber) {
      setCancelModalState('processing');
      setCancelModalMessage('Cancelling change order...');
  
      try {
        const response = await updateStatus('changeOrder', cancelCorNumber, 'Cancelled');
        
        if (response.data.success) {
          setCancelModalState('success');
          setCancelModalMessage('Change order successfully cancelled.');
          setTempStatuses(prevStatuses => ({ ...prevStatuses, [cancelCorNumber]: 'Cancelled' }));
        } else {
          throw new Error(response.data.message || 'Failed to cancel change order');
        }
      } catch (error) {
        console.error("Failed to cancel change order:", error);
        setCancelModalState('error');
        setCancelModalMessage(`Failed to cancel change order: ${error.message}`);
      }
    }
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
    setCancelModalState('confirm');
    setCancelModalMessage('');
    setCancelCorNumber(null);
  };

  const calculateTotalAmount = (orders) => {
    return orders.reduce((total, order) => total + order.amount, 0);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  };

const determineDropDirection = (event) => {
  const button = event.target;
  const dropdownMenu = button.nextElementSibling;
  const buttonRect = button.getBoundingClientRect();
  const spaceBelow = window.innerHeight - buttonRect.bottom;
  const spaceAbove = buttonRect.top;
  const dropdownHeight = dropdownMenu.offsetHeight;

  if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
    return 'up';
  }
  return 'down';
};

const handleDownloadPDF = async (ordersToExport) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/generate-change-orders-pdf`,
      { 
        changeOrders: ordersToExport,
        customer_id: user.customer_id
      },
      { responseType: 'blob' }
    );

    const contentType = response.headers['content-type'];
    if (contentType.includes('application/json')) {
      // Handle error message
      const reader = new FileReader();
      reader.onload = function() {
        const errorData = JSON.parse(reader.result);
        console.error("Error generating PDF:", errorData);
        alert(`Failed to generate PDF: ${errorData.message}`);
      };
      reader.readAsText(response.data);
    } else {
      // Handle successful PDF download
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'change_orders.pdf';
      link.click();
    }
  } catch (error) {
    console.error("Error downloading PDF:", error);
    if (error.response) {
      if (error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = function() {
          try {
            const errorData = JSON.parse(reader.result);
            console.error("Error details:", errorData);
            alert(`Failed to generate PDF: ${errorData.message}`);
          } catch (e) {
            console.error("Error parsing error response:", e);
            alert("Failed to generate PDF. Please check the console for more details.");
          }
        };
        reader.readAsText(error.response.data);
      } else {
        console.error("Response data:", error.response.data);
        alert("Failed to generate PDF. Please check the console for more details.");
      }
    } else if (error.request) {
      console.error("No response received:", error.request);
      alert("Failed to generate PDF. No response received from the server.");
    } else {
      console.error("Error setting up request:", error.message);
      alert("Failed to generate PDF. Error setting up the request.");
    }
  }
};

  const handleComplete = (corNumber) => {
    const message = encodeURIComponent(`Let's complete cor ${corNumber}`);
    navigate(`/assistant?message=${message}`);
    setOpenDropdown(null);
  };

  const toggleExpand = (corNumber) => {
    setExpandedOrder(prevExpanded => prevExpanded === corNumber ? null : corNumber);
  };

  const handleViewChangeOrder = (corNumber) => {
    navigate(`/change-order/${corNumber}`);
  };

  const renderSubTable = (statusGroup, statusOrders, region) => {
    const { includedColumns, columnWidths } = getColumnConfig(statusGroup);
  
    return (
      <div key={`${region}-${statusGroup}`} className="status-section">
        <div className="table-header">
          <h3>{statusGroup}</h3>
          <button
            onClick={() => handleDownloadPDF(statusOrders)}
            className="btn btn-secondary export-button"
          >
            Export COR Log
          </button>
        </div>
        
        <div className="table-responsive">
          {isMobile ? (
            <div className="change-order-table mobile-table">
              {statusOrders.map((order, index) => 
                renderMobileRow(order, index)
              )}
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  {includedColumns.map((column, index) => (
                    <th 
                      key={column} 
                      style={{ width: columnWidths[index] }}
                    >
                      {formatColumnHeader(column)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {statusOrders.map((order) => (
                  <tr key={order.corNumber}>
                    {includedColumns.map((column, index) => 
                      renderCell(column, order, index, columnWidths[index])
                    )}
                  </tr>
                ))}
              </tbody>
              {renderTableFooter(statusOrders, includedColumns, columnWidths)}
            </table>
          )}
        </div>
      </div>
    );
  };
  
  const formatColumnHeader = (column) => {
    const headerMap = {
      daysOld: 'Days Old',
      corNumber: 'COR #',
      description: 'Description',
      jobName: 'Job Name',
      status: 'Status',
      amount: 'Amount',
      complete: 'Action'
    };
    return headerMap[column] || column;
  };
  
  const renderTableFooter = (statusOrders, includedColumns, columnWidths) => {
    return statusOrders.length > 0 ? (
      <tfoot>
        <tr>
          <td colSpan={includedColumns.length - 1} style={{ border: 'none' }}></td>
          <td style={{ fontWeight: 'bold', width: columnWidths[columnWidths.length - 1] }}>
            Total: {formatCurrency(calculateTotalAmount(statusOrders))}
          </td>
        </tr>
      </tfoot>
    ) : null;
  };
  
  const renderContent = () => {
    // Check if the data is region-based
    const isRegionBased = displayOrders && 
                         typeof displayOrders === 'object' && 
                         !Array.isArray(displayOrders);
  
    if (isRegionBased) {
      return renderRegions();
    } else {
      return renderNonRegionalContent(displayOrders);
    }
  };

  const renderNonRegionalContent = (orders) => {
    // Get all unique status groups and sort them
    const allStatusGroups = [...new Set(orders.map(order => getStatusGroup(order.status)))].sort((a, b) => {
      // Put 'Incomplete' first
      if (a === 'Incomplete') return -1;
      if (b === 'Incomplete') return 1;
      return a.localeCompare(b); // Sort other statuses alphabetically
    });
    
    return (
      <>
        {allStatusGroups.map(statusGroup => {
          const statusOrders = orders.filter(order => 
            getStatusGroup(order.status) === statusGroup
          );
  
          if (statusOrders.length === 0) return null;
  
          return renderSubTable(statusGroup, statusOrders, 'all');
        })}
      </>
    );
  };
  
  const renderRegions = () => {
    const regions = Object.entries(displayOrders);
    const isSingleRegion = regions.length === 1;
  
    return regions.map(([region, orders]) => {
      // Get all unique status groups for this region and sort them
      const allStatusGroups = [...new Set(orders.map(order => getStatusGroup(order.status)))].sort((a, b) => {
        // Put 'Incomplete' first
        if (a === 'Incomplete') return -1;
        if (b === 'Incomplete') return 1;
        return a.localeCompare(b); // Sort other statuses alphabetically
      });
      
      return (
        <div key={region} className="region-section mb-4">
          {/* Only show region name if there's more than one region */}
          {!isSingleRegion && <h3 className="region-name">{region}</h3>}
          
          {allStatusGroups.map(statusGroup => {
            const statusOrders = orders.filter(order => 
              getStatusGroup(order.status) === statusGroup
            );
  
            if (statusOrders.length === 0) return null;
  
            return renderSubTable(statusGroup, statusOrders, region);
          })}
        </div>
      );
    });
  };

  const renderMobileRow = (order, index) => {
    const statusClass = 
      (tempStatuses[order.corNumber] || order.status).toLowerCase() === 't&m approved'
        ? 'status-approved'
        : (tempStatuses[order.corNumber] || order.status).toLowerCase() === 'adjustment needed'
        ? 'status-adjustment-needed'
        : (tempStatuses[order.corNumber] || order.status).toLowerCase() === 'awaiting superintendent approval'
        ? 'status-awaiting'
        : '';
  
    const isIncompleteTable = getStatusGroup(order.status) === 'Incomplete';
    const showStatusDropdown = ['Unexecuted', 'Executed', 'Cancelled', 'Deleted'].includes(order.status);
  
    return (
      <div 
        key={order.corNumber} 
        className={`change-order-card ${expandedOrder === order.corNumber ? 'expanded' : ''}`}
      >
        <div className="change-order-info" onClick={() => toggleExpand(order.corNumber)}>
          <div>COR #{order.corNumber}</div>
          <div>
            {order.description
              ? order.description.length > 10
                ? order.description.substring(0, 10) + '...'
                : order.description
              : 'No description'}
          </div>
          <div>{formatCurrency(order.amount)}</div>
        </div>
        <div className="change-order-details">
          <div>Description: {order.description || 'No description'}</div>
          {!excludeJobNames && <div>Job Name: {order.jobName}</div>}
          <div className={`status-cell ${statusClass}`}>
            Status: {
              showStatusDropdown && !isIncompleteTable ? (
                <select
                  value={tempStatuses[order.corNumber] || order.status}
                  onChange={(e) => handleStatusChange(order.corNumber, e.target.value, order.daysOld)}
                  style={{ width: '100%' }}
                  disabled={tempStatuses[order.corNumber] === "Loading..."}
                >
                  {getStatusOptions(order.status).map((status, index) => (
                    <option key={index} value={status}>{status}</option>
                  ))}
                </select>
              ) : (
                tempStatuses[order.corNumber] || order.status
              )
            }
          </div>
          
          {isIncompleteTable ? (
            <Dropdown>
              <Dropdown.Toggle 
                variant="primary" 
                id={`dropdown-mobile-${order.corNumber}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (openDropdown === order.corNumber) {
                    setOpenDropdown(null);
                  } else {
                    setOpenDropdown(order.corNumber);
                  }
                }}
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu show={openDropdown === order.corNumber}>
                {order.status === 'Awaiting Superintendent Approval' && (
                  <Dropdown.Item 
                    className="dropdown-item btn-warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemindSuperintendent(order.corNumber);
                    }}
                  >
                    <FontAwesomeIcon icon={faBell} /> Send Reminder
                  </Dropdown.Item>
                )}
                {(order.status === 'Incomplete' || order.status === 'T&M Approved' || order.status === 'Adjustment Needed') && 
                order.status !== 'Awaiting Superintendent Approval' && (
                  <Dropdown.Item 
                    className="dropdown-item btn-primary"
                    onClick={() => handleComplete(order.corNumber)}
                  >
                    <FontAwesomeIcon icon={faCheck} /> Complete
                  </Dropdown.Item>
                )}
                <Dropdown.Item 
                  className="dropdown-item btn-danger"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(order.corNumber);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
          
          <button
            className="btn btn-secondary btn-sm mt-2"
            onClick={() => handleViewChangeOrder(order.corNumber)}
          >
            View Change Order
          </button>
        </div>
      </div>
    );
  };
  
  const renderCell = (column, order, index, columnWidth) => {
    const cellStyle = {
      width: columnWidth,
      cursor: 'pointer'
    };
  
    switch (column) {
      case 'daysOld':
        const daysOldStyle = {
          ...cellStyle,
          textAlign: 'center',
          color: order.daysOld > 45 ? 'red' : 'inherit',
          fontWeight: order.daysOld > 45 ? 'bold' : 'normal',
        };
        return (
          <td
            key={'daysOld' + order.corNumber}
            style={daysOldStyle}
            onMouseEnter={() => handleMouseEnter(order.corNumber)}
            onMouseLeave={handleMouseLeave}
            className={hoveredRow === order.corNumber ? 'hover-active' : ''}
          >
            {order.daysOld}
          </td>
        );
      case 'corNumber':
        return (
          <td
            key={'corNumber' + order.corNumber}
            style={cellStyle}
            onMouseEnter={() => handleMouseEnter(order.corNumber)}
            onMouseLeave={handleMouseLeave}
            className={hoveredRow === order.corNumber ? 'hover-active' : ''}
            onClick={() => handleCorSelection(order.corNumber, order.old_cor)}
          >
            {order.corNumber}
          </td>
        );
      case 'description':
        return (
          <td
            key={'description' + order.corNumber}
            style={cellStyle}
            onMouseEnter={() => handleMouseEnter(order.corNumber)}
            onMouseLeave={handleMouseLeave}
            className={hoveredRow === order.corNumber ? 'hover-active' : ''}
            onClick={() => handleCorSelection(order.corNumber, order.old_cor)}
          >
            {order.description}
          </td>
        );
      case 'jobName':
        return (
          <td
            key={'jobName' + order.corNumber}
            style={cellStyle}
            onMouseEnter={() => handleMouseEnterCor(order.corNumber)}
            onMouseLeave={handleMouseLeaveCor}
            className={hoveredCor === order.corNumber ? 'hover-active' : ''}
            onClick={() => handleSelectJob(order.jobName)}
          >
            {order.jobName}
          </td>
        );
        case 'status':
          const displayStatus = tempStatuses.hasOwnProperty(order.corNumber) ? tempStatuses[order.corNumber] : order.status;
          const showStatusDropdown = ['Unexecuted', 'Executed', 'Cancelled', 'Deleted'].includes(order.status);
          
          const statusClass = 
            (tempStatuses[order.corNumber] || order.status).toLowerCase() === 't&m approved'
              ? 'status-approved'
              : (tempStatuses[order.corNumber] || order.status).toLowerCase() === 'adjustment needed'
              ? 'status-adjustment-needed'
              : (tempStatuses[order.corNumber] || order.status).toLowerCase() === 'awaiting superintendent approval'
              ? 'status-awaiting'
              : '';
        
          return (
            <td
              key={'status' + order.corNumber}
              style={cellStyle}
              className={`status-cell ${statusClass}`}
            >
              {showStatusDropdown ? (
                <select
                  value={displayStatus}
                  onChange={(e) => handleStatusChange(order.corNumber, e.target.value, order.daysOld)}
                  style={{ width: '100%' }}
                  disabled={displayStatus === "Loading..."}
                >
                  {getStatusOptions(order.status).map((status, index) => (
                    <option key={index} value={status}>{status}</option>
                  ))}
                </select>
              ) : (
                displayStatus
              )}
            </td>
          );
      case 'amount':
        return (
          <td key={'amount' + order.corNumber}>
            {formatCurrency(order.amount)}
          </td>
        );
      case 'complete':
        return (
          <td key={'action' + order.corNumber} style={cellStyle}>
            <div className="dropdown">
              <button
                className="btn btn-primary dropdown-toggle"
                type="button"
                id={`dropdown-${order.corNumber}`}
                onClick={(e) => {
                  e.stopPropagation();
                  const direction = determineDropDirection(e);
                  e.currentTarget.parentElement.classList.toggle('dropup', direction === 'up');
                  if (openDropdown === order.corNumber) {
                    setOpenDropdown(null);
                  } else {
                    setOpenDropdown(order.corNumber);
                  }
                }}
              >
                Actions
              </button>
              <ul 
                className={`dropdown-menu ${openDropdown === order.corNumber ? 'show' : ''}`}
                aria-labelledby={`dropdown-${order.corNumber}`}
              >          
                {order.status === 'Awaiting Superintendent Approval' && (
                  <li>
                    <button 
                      className="dropdown-item btn-warning"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemindSuperintendent(order.corNumber);
                      }}
                    >
                      <FontAwesomeIcon icon={faBell} /> Send Reminder
                    </button>
                  </li>
                )}
                {(order.status === 'Incomplete' || order.status === 'T&M Approved') && 
                order.status !== 'Awaiting Superintendent Approval' && 
                order.status !== 'Adjustment Needed' && (
                  <li>
                    <button 
                      className="dropdown-item btn-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleComplete(order.corNumber);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} /> Complete
                    </button>
                  </li>
                )}
                {order.status === 'Adjustment Needed' && (
                  <li>
                    <button 
                      className="dropdown-item btn-warning"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/change-order/${order.corNumber}`);
                        setTimeout(() => {
                          const editButton = document.querySelector('.edit-button');
                          if (editButton) {
                            editButton.click();
                          }
                        }, 100);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} /> Update
                    </button>
                  </li>
                )}
                <li>
                <button 
                  className="dropdown-item btn-danger"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(order.corNumber);
                  }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </li>
              </ul>
            </div>
          </td>
        );
      default:
        return <td key={column + order.corNumber} style={cellStyle}></td>;
    }
  };

  if (!displayOrders || 
    (Array.isArray(displayOrders) && displayOrders.length === 0) || 
    (typeof displayOrders === 'object' && Object.keys(displayOrders).length === 0)) {
  return null;
}

    return (
      <div className="change-orders-table">
        {renderContent()}
        <ConfirmationModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleConfirm={confirmAction}
          message={modalMessage}
          confirmButtonText={confirmButtonText}
          showCancelButton={showCancelButton}
        />
        <ConfirmationModal
          show={showCancelModal}
          handleClose={handleCancelModalClose}
          handleConfirm={cancelModalState === 'confirm' ? confirmCancellation : handleCancelModalClose}
          message={cancelModalState === 'confirm' ? 'Are you sure you want to cancel this change order?' : cancelModalMessage}
          confirmButtonText={cancelModalState === 'confirm' ? 'Yes, Cancel' : 'OK'}
          showCancelButton={cancelModalState === 'confirm'}
        />
        <ConfirmationModal
          show={showReminderModal}
          handleClose={() => setShowReminderModal(false)}
          handleConfirm={() => setShowReminderModal(false)}
          message={reminderModalMessage}
          confirmButtonText="Close"
          showCancelButton={false}
        />
      </div>
    );
  };
  export default ChangeOrdersTable;