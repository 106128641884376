// src/modules/Analytics/Components/RouterAnalytics/ChartConfig.js
import { enUS } from 'date-fns/locale';
import { formatTimestamp } from '../../utils/dateFormatters';

export const getChartOptions = (selectedRange, bucketedData, setShowRequestTable, onRequestClick) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const element = elements[0];
        const datasetIndex = element.datasetIndex;
        
        if (datasetIndex === 1) {  // Individual requests dataset
          const chart = event.chart;
          const dataPoint = chart.data.datasets[1].data[element.index];
          
          if (dataPoint && dataPoint.requestId) {
            if (!chart.isDatasetVisible(1)) {
              chart.show(1);
              setShowRequestTable(true);
            }
            onRequestClick(dataPoint.requestId);
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          
          if (legendItem.text === 'Interpolated Data') {
            return;
          }
   
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
          } else {
            ci.show(index);
          }
   
          if (legendItem.text === 'Individual Requests') {
            setShowRequestTable(ci.isDatasetVisible(index));
          }
        },
        labels: {
          filter: (legendItem) => legendItem.text !== 'Interpolated Data'
        }
      },
      tooltip: {
        callbacks: {
          afterBody: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            if (!tooltipItem) return [];
      
            if (tooltipItem.dataset.label === 'Individual Requests') {
              const dataPoint = tooltipItem.raw;
              return [
                `Request ID: ${dataPoint.requestId}`,
                `Processing Time: ${dataPoint.time.toFixed(3)}s`,
                `Timestamp: ${formatTimestamp(dataPoint.x, 'full')}`
              ];
            } else {
              const dataPoint = bucketedData[tooltipItem.dataIndex];
              if (!dataPoint) return [];
      
              return [
                `Data points in interval: ${dataPoint.dataPoints}`,
                `Time range: ${formatTimestamp(dataPoint.timestamp, selectedRange)}`
              ];
            }
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: selectedRange === '1h' ? 'minute' :
                selectedRange === '1d' ? 'hour' :
                selectedRange === '1w' ? 'day' :
                selectedRange === '1m' ? 'day' :
                'month',
          stepSize: selectedRange === '1h' ? 5 : 
                    selectedRange === '1d' ? 2 : 
                    selectedRange === '1w' ? 1 : 
                    selectedRange === '1m' ? 2 : 
                    1,
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'MMM d',
            month: 'MMM yyyy'
          }
        },
        adapters: {
          date: {
            locale: enUS
          }
        },
        min: Math.min(
          ...bucketedData.map(d => d.timestamp.getTime())
        ),
        max: (() => {
          const currentTime = new Date();
          switch(selectedRange) {
            case '1h':
              currentTime.setMinutes(currentTime.getMinutes() + 3); // 5% of 60 minutes
              break;
            case '1d':
              currentTime.setHours(currentTime.getHours() + 2); // ~8% of 24 hours
              break;
            case '1w':
              currentTime.setHours(currentTime.getHours() + 12); // ~7% of a week
              break;
            case '1m':
              currentTime.setDate(currentTime.getDate() + 2); // ~6-7% of a month
              break;
            case '1y':
              currentTime.setDate(currentTime.getDate() + 14); // ~4% of a year
              break;
            default:
              currentTime.setHours(currentTime.getHours() + 2); // Default to 24h behavior
              break;
          }
          return currentTime.getTime();
        })()
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Processing Time (seconds)'
        }
      }
    }
  };
};

export const getChartData = (bucketedData, individualRequests, gapData) => {
  return {
    datasets: [
      {
        label: 'Average Router Processing Time',
        data: bucketedData.map(point => ({
          x: point.timestamp,
          y: point.avg_time
        })),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        tension: 0.1,
        spanGaps: false,
        pointRadius: (context) => {
          const dataPoint = bucketedData[context.dataIndex];
          return dataPoint.dataPoints > 0 ? Math.min(Math.max(4, dataPoint.dataPoints), 8) : 0;
        },
        pointBackgroundColor: (context) => {
          const dataPoint = bucketedData[context.dataIndex];
          const alpha = Math.min(dataPoint.dataPoints / 10, 1);
          return `rgba(75, 192, 192, ${alpha})`;
        }
      },
      {
        label: 'Individual Requests',
        data: individualRequests.map(record => ({
          x: new Date(record.timestamp),
          y: record.checkpoints.router,
          requestId: record.request_id,
          time: record.checkpoints.router
        })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        pointRadius: 4,
        pointHoverRadius: 6,
        tension: 0.1,
        spanGaps: true,
        hidden: true
      },
      {
        label: 'Interpolated Data',
        data: gapData,
        borderColor: 'rgba(128, 128, 128, 0.5)',
        borderDash: [5, 5],
        tension: 0.1,
        pointRadius: 0,
        spanGaps: true
      }
    ]
  };
};