// ManageAttachmentsModal.js
import React, { useState, useRef } from 'react';
import { Modal, Button, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';
import './ManageAttachmentsModal.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ManageAttachmentsModal = ({ 
  show, 
  onHide, 
  user, 
  initialCorNumber, 
  attachments,
  onAttachmentsChange
}) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check file size first
    if (file.size > MAX_FILE_SIZE) {
      setError('File too large. Please upload smaller files.');
      event.target.value = '';
      return;
    }

    const validTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (!validTypes.includes(file.type)) {
      setError('Only PNG, JPEG, and PDF files are allowed.');
      event.target.value = '';
      return;
    }

    setError('');
    setUploading(true);
    const formData = new FormData();
    formData.append('customer_id', user.customer_id);
    formData.append('cor_number', initialCorNumber);
    formData.append('file', file);

    try {
      await axios.post(`${API_BASE_URL}/api/upload-change-order-attachment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await onAttachmentsChange();
      setUploading(false);
      event.target.value = '';
    } catch (error) {
      console.error('Error uploading attachment:', error);
      setError('An error occurred while uploading the file. Please try again.');
      setUploading(false);
      event.target.value = '';
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDelete = async (blobName) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-change-order-attachment`, {
        params: { blob_name: blobName, customer_id: user.customer_id },
      });
      await onAttachmentsChange();
    } catch (error) {
      console.error('Error deleting attachment:', error);
      setError('Failed to delete attachment. Please try again.');
    }
  };

  const handleDownload = async (blobName, originalFileName) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/download-change-order-attachment`, {
        params: { blob_name: blobName, customer_id: user.customer_id },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', originalFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading attachment:', error);
      setError('Failed to download attachment. Please try again.');
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" className="mam-root">
      <Modal.Header closeButton>
        <Modal.Title>Manage Attachments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
        <div className="d-flex justify-content-between mb-3">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".pdf,.png,.jpg,.jpeg"
          />
          <small className="text-muted">
            Maximum file size: {formatFileSize(MAX_FILE_SIZE)}
          </small>
        </div>
        <div className="mam-table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Filename</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {attachments.map((attachment) => (
                <tr key={attachment.blobName}>
                  <td>{attachment.originalFileName}</td>
                  <td>
                    <div className="d-flex justify-content-center mam-action-buttons">
                      <Button 
                        variant="danger" 
                        onClick={() => handleDelete(attachment.blobName)} 
                        className="me-2"
                      >
                        Delete
                      </Button>
                      <Button 
                        variant="secondary" 
                        onClick={() => handleDownload(attachment.blobName, attachment.originalFileName)}
                      >
                        Download
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              {attachments.length === 0 && (
                <tr>
                  <td colSpan="2" className="text-center">
                    No attachments found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <Button 
            variant="primary" 
            onClick={handleUploadButtonClick} 
            disabled={uploading} 
            className="mam-upload-btn"
          >
            {uploading ? 'Uploading...' : 'Add new attachment'}
          </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageAttachmentsModal;