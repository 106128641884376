import React from 'react';
import { FaTimes } from 'react-icons/fa';

const FileAttachments = ({ selectedFiles, clearSelectedFile }) => (
  <div className="attachments">
    {selectedFiles.map((file, index) => (
      <div key={index} className="attachment">
        {file.name}
        <FaTimes className="clear-file-icon" onClick={() => clearSelectedFile(index)} />
      </div>
    ))}
  </div>
);

export default FileAttachments;