// src/modules/Analytics/Components/LoginTracker.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import TimelineFilter, { calculateDateRange } from '../TimelineFilter';
import API_BASE_URL from '../../../constants/apiConfig';

const LoginTracker = ({ isVisible }) => {
 const [loginData, setLoginData] = useState(null);
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState(null);
 const [selectedRange, setSelectedRange] = useState('1d');
 const [selectedEnvironment, setSelectedEnvironment] = useState('prod');

  const fetchLoginData = useCallback(async (range) => {
    if (!isVisible) return;
    
    setIsLoading(true);
    try {
      const { startDate, endDate } = calculateDateRange(range, selectedEnvironment);
      const params = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
        environment: selectedEnvironment
      });
      
      const response = await axios.get(`${API_BASE_URL}/api/metrics/logins?${params.toString()}`, {
        withCredentials: true
      });

      if (response.data.success) {
        setLoginData(response.data.data);
        setError(null);
      } else {
        throw new Error(response.data.message || 'Failed to fetch login data');
      }
    } catch (err) {
      console.error('Error fetching login data:', err);
      setError('Unable to load login data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [isVisible, selectedEnvironment]);

 useEffect(() => {
   if (isVisible) {
     fetchLoginData(selectedRange);
     const interval = setInterval(() => fetchLoginData(selectedRange), 60000);
     return () => clearInterval(interval);
   }
 }, [selectedRange, fetchLoginData, isVisible]);

 if (!isVisible) {
   return null;
 }

const renderContent = () => {
  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (isLoading) {
    return (
      <div className="text-center p-4">
        <Spinner animation="border" />
        <p>Loading login data...</p>
      </div>
    );
  }

  if (!loginData) {
    return <div className="alert alert-info">No login data available</div>;
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Total Logins</h5>
              <h2>{loginData.total_logins}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive mt-4">
        <h4>Recent Login Activity</h4>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Phone Number</th> 
              <th>Status</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {loginData.recent_logins.map((login, index) => (
              <tr key={index} className={login.success ? 'table-success' : 'table-danger'}>
                <td>{new Date(login.timestamp).toLocaleString()}</td>
                <td>{login.phone}</td>
                <td>{login.success ? 'Success' : 'Failed'}</td>
                <td>{login.location || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

 return (
   <div className="login-analytics-dashboard">
     <div className="dashboard-card">
       <h3 className="card-title">Login Analytics</h3>
       <div className="card-content">
         <div className="d-flex justify-content-between align-items-center">
           <TimelineFilter
             selectedRange={selectedRange}
             onRangeChange={setSelectedRange}
             refreshButton={true}
             onRefresh={() => fetchLoginData(selectedRange)}
             isLoading={isLoading}
           />
           <select
             className="form-select ms-3"
             style={{ width: 'auto' }}
             value={selectedEnvironment}
             onChange={(e) => setSelectedEnvironment(e.target.value)}
           >
             <option value="prod">Production</option>
             <option value="dev">Development</option>
             <option value="staging">Staging</option>
           </select>
         </div>
         {renderContent()}
       </div>
     </div>
   </div>
 );
};

export default LoginTracker;