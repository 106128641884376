/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/useDownloadPdf.js
import { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';
import fileDownload from 'js-file-download';

const useDownloadPdf = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const downloadPdf = async ({ type, user, cor_number, change_orders_dict }) => {
    if (!user || !user.customer_id || !user.phone) {
      console.error('User information is incomplete for PDF download.');
      setError(new Error('User information is incomplete.'));
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/download-pdf`, {
        params: {
          type,
          customer_id: user.customer_id,
          phone: user.phone,
          cor_number,
          change_orders_dict,
        },
        responseType: 'blob', // Important to process the PDF binary
      });
      fileDownload(response.data, `ChangeOrder_${cor_number}.pdf`);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError(error);
      setIsLoading(false);
    }
  };

  return { downloadPdf, isLoading, error };
};

export default useDownloadPdf;
