/* Used by: redux/reducers/index.js */

// src/modules/ChangeOrderComponents/redux/reducers/changeOrderReducer.js
import {
  FETCH_CHANGE_ORDER_REQUEST,
  FETCH_CHANGE_ORDER_SUCCESS,
  FETCH_CHANGE_ORDER_FAILURE,
  UPDATE_EDIT_VALUES,
  SAVE_CHANGE_ORDER_REQUEST,
  SAVE_CHANGE_ORDER_SUCCESS,
  SAVE_CHANGE_ORDER_FAILURE,
} from './changeOrderActions';

const initialState = {
  loading: false,
  error: null,
  isEditable: false,
  originalChangeOrderDetails: null,
  changeOrderDetails: {
    corNumber: null,
    customerName: '',
    customerPhone: '',
    customerAddressStreet: '',
    customerAddressCity: '',
    customerAddressState: '',
    customerAddressZip: '',
    bigDict: {
      total_costs: {
        base_cost: 0,
        po_amount: 0,
        final_total: 0
      },
    },
    jobName: '',
    gcName: '',
    description: '',
  },
  jobOptions: {},
  editValues: {},
};

const changeOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANGE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CHANGE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        changeOrderDetails: action.payload.changeOrderDetails,
        originalChangeOrderDetails: action.payload.changeOrderDetails,
        jobOptions: action.payload.jobOptions,
        editValues: action.payload.isNewOrder ? action.payload.changeOrderDetails.bigDict : state.editValues,
      };
  case FETCH_CHANGE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_EDIT_VALUES:
      return {
        ...state,
        editValues: action.payload,
      };
    case SAVE_CHANGE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case SAVE_CHANGE_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          changeOrderDetails: action.payload,
        };
    case SAVE_CHANGE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default changeOrderReducer;