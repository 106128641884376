import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditValues } from '../redux/changeOrderActions';
import './Categories.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditableCategories = () => {
  const dispatch = useDispatch();
  const { editValues } = useSelector((state) => state.changeOrder);
  const { categories } = editValues;

  const CATEGORY_ORDER = ['labor', 'materials', 'equipment', 'other'];

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const handleCategoryChange = (categoryName, itemName, field, value) => {
    let parsedValue = value;
    
    if (field === 'cost_per_unit') {
      parsedValue = value === '' ? '' : parseFloat(value);
    } else if (field === 'quantity') {
      parsedValue = value === '' ? '' : parseInt(value, 10);
    }

    let updatedCategories = {
      ...categories,
      [categoryName]: {
        ...categories[categoryName],
        [itemName]: {
          ...categories[categoryName][itemName],
          [field]: parsedValue,
        },
      },
    };

    if (field === 'itemName') {
      updatedCategories = {
        ...categories,
        [categoryName]: Object.entries(categories[categoryName]).reduce((acc, [key, val]) => {
          if (key === itemName) {
            acc[value] = val;
          } else {
            acc[key] = val;
          }
          return acc;
        }, {}),
      };
    }

    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const handleAddItem = (categoryName) => {
    const newItemName = `New Item ${Object.keys(categories[categoryName]).length + 1}`;
    const updatedCategories = {
      ...categories,
      [categoryName]: {
        ...categories[categoryName],
        [newItemName]: {
          id: `temp-${categoryName}-${newItemName}`,
          cost_per_unit: 0,
          unit: 'ea',
          quantity: 0,
        },
      },
    };
    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const handleRemoveItem = (categoryName, itemName) => {
    const updatedCategories = {
      ...categories,
      [categoryName]: Object.entries(categories[categoryName])
        .filter(([key]) => key !== itemName)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
    };
    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const renderEditableCategorySection = (categoryData, categoryName) => {
    const subtotal = Object.entries(categoryData).reduce((total, [_, itemDetails]) => {
      return total + (itemDetails.quantity * parseFloat(itemDetails.cost_per_unit || 0));
    }, 0);
  
    const categoryEntries = Object.entries(categoryData);
  
    return (
      <div className={`category-section mb-4 ${categoryName.toLowerCase()}`}>
        <div className="category-header d-flex justify-content-between align-items-center mb-3">
          <h3 className="m-0">{capitalize(categoryName)}</h3>
          <button 
            onClick={() => handleAddItem(categoryName.toLowerCase())} 
            className="btn btn-sm btn-primary"
          >
            Add New Item
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th>Item</th>
                <th>Cost / Unit</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categoryEntries.map(([itemName, itemDetails]) => (
                <tr key={itemDetails.id}>
                  <td>
                    <input
                      type="text"
                      value={itemName}
                      onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemName, 'itemName', e.target.value)}
                      className="form-control form-control-sm"
                    />
                  </td>
                  <td>
                    <div className="input-group input-group-sm">
                      <input
                        type="number"
                        value={itemDetails.cost_per_unit}
                        onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemName, 'cost_per_unit', e.target.value)}
                        className="form-control form-control-sm"
                        step="0.01"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">/</span>
                      </div>
                      <input
                        type="text"
                        value={itemDetails.unit}
                        onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemName, 'unit', e.target.value)}
                        className="form-control form-control-sm"
                        style={{ width: '50px' }}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={itemDetails.quantity}
                      onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemName, 'quantity', e.target.value)}
                      className="form-control form-control-sm"
                      min="0"
                    />
                  </td>
                  <td>{formatCurrency(itemDetails.quantity * parseFloat(itemDetails.cost_per_unit || 0))}</td>
                  <td>
                    <button onClick={() => handleRemoveItem(categoryName.toLowerCase(), itemName)} className="btn btn-sm btn-danger">
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="subtotal-row">
                <td></td>
                <td></td>
                <td className="text-right"><strong>Subtotal</strong></td>
                <td><strong>{formatCurrency(subtotal)}</strong></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="editable-categories">
      {CATEGORY_ORDER.map((categoryName) => {
        const categoryData = categories[categoryName];
        if (categoryData) {
          return renderEditableCategorySection(categoryData, categoryName);
        }
        return null;
      })}
    </div>
  );
};

export default EditableCategories;