// src/modules/ChangeOrderComponents/JobInfo/JobInfo.js
import React from 'react';
import './JobInfo.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const JobInfo = ({ job }) => {
  if (!job) return null;

  const { jobName, gcName } = job;

  return (
    <div className="job-info-component mb-4">
      <h5 className="job-info-component__title mb-3">Job Information</h5>
      <p className="job-info-component__text"><strong>Job Name:</strong> {jobName}</p>
      <p className="job-info-component__text"><strong>General Contractor:</strong> {gcName}</p>
    </div>
  );
};

export default JobInfo;