import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../../constants/apiConfig';

const CreditsAndUsage = () => {
  const [creditsInfo, setCreditsInfo] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      if (user && user.customer_id) {
        try {
          setIsLoading(true);
          const creditsResponse = await fetch(`${API_BASE_URL}/api/credits-info?customer_id=${user.customer_id}`);
          const subscriptionResponse = await fetch(`${API_BASE_URL}/api/subscription-info?customer_id=${user.customer_id}`);
          
          if (!creditsResponse.ok) {
            throw new Error('Failed to fetch credits information');
          }
          
          const creditsData = await creditsResponse.json();
          setCreditsInfo(creditsData);

          if (subscriptionResponse.ok) {
            const subscriptionData = await subscriptionResponse.json();
            setSubscriptionInfo(subscriptionData);
          } else {
            console.warn('Subscription information not available');
          }
        } catch (error) {
          console.error('Error fetching billing information:', error);
          setError(error.message || 'Failed to load billing information. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBillingInfo();
  }, [user]);

  if (isLoading) {
    return <div>Loading billing information...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div>
      {creditsInfo && (
        <div style={{ marginBottom: '15px' }}>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Available Credits: {creditsInfo.isUnlimited ? 'Unlimited' : creditsInfo.availableCredits}
          </p>
        </div>
      )}
      {subscriptionInfo && subscriptionInfo.length > 0 ? (
        subscriptionInfo.map((subscription, index) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <p><strong>Next Renewal Date:</strong> {new Date(subscription.nextRenewalDate * 1000).toLocaleDateString()}</p>
            <p><strong>Base Subscription Amount:</strong> ${subscription.baseChargeAmount.toFixed(2)}</p>
            <p><strong>Additional Charges:</strong> ${subscription.usageCharges.toFixed(2)}</p>
            <p><strong>Total Upcoming Bill:</strong> ${subscription.totalAmountDue.toFixed(2)}</p>
          </div>
        ))
      ) : (
        <p>Subscription information is currently unavailable.</p>
      )}
    </div>
  );
};

export default CreditsAndUsage;