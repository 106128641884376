/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/utils.js
export const jobNameAndGC = (bigDict, jobOptions) => {
    if (!bigDict || !jobOptions) {
      return { jobName: '', gcName: '' };
    }
  
    const jobName = bigDict.job_name;
    const gcName = jobOptions[jobName];
  
    return { jobName, gcName };
  };

