import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useFilteredChangeOrderMetrics from './useFilteredChangeOrderMetrics';
import SharedPieChart from './PieCharts/SharedPieCharts';
import TimelineFilter from './TimelineFilter';
import DevReturnButton from './DevReturnButton';
import ChangeOrdersTable from '../shared/ChangeOrdersTable/ChangeOrdersTable';
import API_BASE_URL from '../../constants/apiConfig';
import { Spinner } from 'react-bootstrap';
import OnboardingModal from './OnboardingModal';
import './PieCharts/SharedPieCharts.css';
import './TimelineFilter.css';
import './Dashboard.css';

const Dashboard = () => {
 const navigate = useNavigate();
 const [allChangeOrders, setAllChangeOrders] = useState({});
 const [isLoading, setIsLoading] = useState(false);
 const [error, setError] = useState(null);
 const user = useSelector((state) => state.user.user);
 const [selectedStatus, setSelectedStatus] = useState(null);
 const tableRef = useRef(null);
 const [showOnboardingModal, setShowOnboardingModal] = useState(false);

 useEffect(() => {
   const checkOnboardingStatus = async () => {
     if (user?.customer_id) {
       try {
         const response = await axios.get(`${API_BASE_URL}/check-onboarding-status`, {
           params: { customer_id: user.customer_id },
           withCredentials: true,
         });
         if (response.data.success) {
           setShowOnboardingModal(!response.data.onboarded);
         }
       } catch (error) {
         console.error('Error checking onboarding status:', error);
       }
     }
   };

   checkOnboardingStatus();
 }, [user]);

 useEffect(() => {
   const fetchAllChangeOrders = async () => {
     if (user?.customer_id) {
       setIsLoading(true);
       setError(null);
       try {
         const response = await axios.get(`${API_BASE_URL}/all-change-orders`, {
           params: { customer_id: user.customer_id },
         });
   
         if (response.data.success && response.data.data.regions) {
           setAllChangeOrders(response.data.data.regions);
         } else {
           throw new Error(response.data.message || 'Failed to fetch change orders');
         }
       } catch (error) {
         console.error('Error fetching all change orders:', error);
         setError(error);
         setAllChangeOrders({});
       } finally {
         setIsLoading(false);
       }
     }
   };

   fetchAllChangeOrders();
 }, [user?.customer_id]);

 const {
  timeRange,
  filteredChangeOrdersMetrics,
  pieChartData,
  handleTimeRangeChange,
  filteredRegions 
} = useFilteredChangeOrderMetrics(allChangeOrders);

 useEffect(() => {
   if (selectedStatus && tableRef.current) {
     tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
   }
 }, [selectedStatus]);

 const handleCreateNewOrder = () => {
   navigate('/change-order/new');
 };

 const handleGoToAssistant = () => {
   navigate(`/assistant?message=${encodeURIComponent("Let's start onboarding")}`);
 };

 const handleGoToSupport = () => {
   navigate('/support?openSection=feedback');
 };

 const renderMetrics = () => {
   const metrics = filteredChangeOrdersMetrics;
   return (
     <ul className="list-unstyled mt-3 metrics-list">
       {Object.entries(metrics)
         .filter(([key]) => key !== 'Total' && key !== 'Incomplete')
         .map(([key, value]) => (
           <li key={key}>
             <span
               className="stat-label clickable"
               onClick={() => setSelectedStatus(key)}
             >
               {key}:
             </span>
             <span className="stat-count">{value.count.toLocaleString()}</span>
             <span className="stat-value">
               {value.totalValue.toLocaleString('en-US', {
                 style: 'currency',
                 currency: 'USD',
               })}
             </span>
           </li>
         ))}
       <li className="total-separator"></li>
       <li className="total-row">
         <span className="stat-label">Total:</span>
         <span className="stat-count">{metrics.Total.count.toLocaleString()}</span>
         <span className="stat-value">
           {metrics.Total.totalValue.toLocaleString('en-US', {
             style: 'currency',
             currency: 'USD',
           })}
         </span>
       </li>
     </ul>
   );
 };

 const renderChangeOrderSections = () => {
  // Filter out executed, cancelled, and deleted change orders
  const filteredChangeOrders = {};
  
  Object.entries(allChangeOrders).forEach(([region, orders]) => {
    const filteredOrders = orders.filter(order => 
      !['Executed', 'Cancelled', 'Deleted'].includes(order.status)
    );
    
    if (filteredOrders.length > 0) {
      filteredChangeOrders[region] = filteredOrders;
    }
  });

  return (
    <div className="change-orders-section">
      <ChangeOrdersTable 
        changeOrders={filteredChangeOrders}
      />
    </div>
  );
};

const renderFilteredChangeOrders = () => {
  if (!selectedStatus) return null;

  const filteredChangeOrders = Object.entries(filteredRegions).reduce((filtered, [region, orders]) => {
    const filteredOrders = orders.filter(order => order.status === selectedStatus);
    if (filteredOrders.length > 0) {
      filtered[region] = filteredOrders;
    }
    return filtered;
  }, {});

  return (
    <TransitionGroup>
      <CSSTransition
        key={selectedStatus}
        timeout={300}
        classNames="table-transition"
        unmountOnExit
        onEntered={() => {
          if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }}
      >
        <div ref={tableRef} className="change-orders-section filtered-orders-box">
          <h3>
            {selectedStatus} Change Orders
            <button
              className="btn btn-link ms-2"
              onClick={() => setSelectedStatus(null)}
            >
              Close
            </button>
          </h3>
          <ChangeOrdersTable changeOrders={filteredChangeOrders} />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

 if (error) {
   return <div>Error loading change orders: {error.message}</div>;
 }

 return (
   <div className="dashboard-wrapper">
     <div className="feedback-prompt-container">
       <div className="feedback-prompt d-flex align-items-center justify-content-center flex-wrap">
         <span className="feedback-text me-2">We are constantly trying to improve your experience. If you have any recommendations, let us know!</span>
         <a href="/support" className="feedback-link" onClick={(e) => { e.preventDefault(); handleGoToSupport(); }}>
           Provide Feedback
         </a>
       </div>
     </div>
     <div className="container dashboard-root">
       <div className="card shadow">
         <div className="card-body">
           <div className="d-flex justify-content-between align-items-center mb-4">
             <h2>Dashboard</h2>
             <div>
               <DevReturnButton />
               <button className="btn btn-primary ms-2" onClick={handleCreateNewOrder}>
                 Create New COR
               </button>
             </div>
           </div>
 
           {isLoading ? (
             <div className="loading-indicator text-center mt-5">
               <Spinner animation="border" role="status">
                 <span className="visually-hidden">Loading...</span>
               </Spinner>
               <p>Loading change orders...</p>
             </div>
           ) : (
             <>
               <div className="row mb-4">
                 <div className="col-lg-8 mb-4 mb-lg-0">
                   <div className="dashboard-card h-100">
                     <h3 className="card-title">Status Overview</h3>
                     <div className="card-content pie-chart-container">
                       {pieChartData && (
                         <SharedPieChart
                           data={pieChartData}
                           tooltipFormatter={(value, name, props) => [
                             `Count: ${props.payload.count.toLocaleString()}`,
                             `Total Value: $${parseFloat(props.payload.totalValue).toFixed(2)}`,
                             `Name: ${name}`,
                           ]}
                         />
                       )}
                       {filteredChangeOrdersMetrics.Total.count === 0 && (
                         <p className="text-center mt-3">No change orders available</p>
                       )}
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-4">
                   <div className="dashboard-card h-100">
                     <h3 className="card-title">Metrics</h3>
                     <div className="card-content metrics-container">
                       <TimelineFilter
                         selectedRange={timeRange}
                         onFilterChange={handleTimeRangeChange}
                       />
                       {renderMetrics()}
                     </div>
                   </div>
                 </div>
               </div>

               {renderFilteredChangeOrders()}
               {renderChangeOrderSections()}
             </>
           )}
         </div>
       </div>
 
       <OnboardingModal
         show={showOnboardingModal}
         onGoToAssistant={() => handleGoToAssistant("Let's start onboarding")}
       />
     </div>
   </div>
 );
};

export default Dashboard;