import React from 'react';
import { FaPaperPlane, FaPaperclip } from 'react-icons/fa';

const InputArea = ({ inputText, handleInputChange, handleKeyPress, handleFileChange, handleSendMessage }) => (
  <div className="input-container">
    <textarea
      className="form-control message-input"
      placeholder="Type your message..."
      value={inputText}
      onChange={handleInputChange}
      onKeyPress={handleKeyPress}
    />
    <div className="file-input-container">
      <label htmlFor="file-input" className="file-input-label">
        <FaPaperclip className="file-icon" />
      </label>
      <input id="file-input" type="file" onChange={handleFileChange} multiple />
    </div>
    <button className="send-button" onClick={handleSendMessage}>
      <FaPaperPlane className="send-icon" />
    </button>
  </div>
);

export default InputArea;