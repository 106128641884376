// BasePaymentForm.js
import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const BasePaymentForm = ({ onSubmit, onCancel, submitButtonText, cancelButtonText }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !email) {
      return;
    }

    setIsProcessing(true);
    setErrorMessage('');

    try {
      await onSubmit(stripe, elements, email);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <div>
        <label htmlFor="email">Billing Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
      </div>
      <PaymentElement />
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
        <button 
          type="submit" 
          disabled={isProcessing || !stripe || !elements || !email}
          style={{ 
            padding: '10px 20px', 
            backgroundColor: (isProcessing || !stripe || !elements || !email) ? '#cccccc' : '#4CAF50', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px', 
            cursor: (isProcessing || !stripe || !elements || !email) ? 'not-allowed' : 'pointer' 
          }}
        >
          {isProcessing ? 'Processing...' : submitButtonText}
        </button>
        {onCancel && (
          <button 
            type="button" 
            onClick={onCancel} 
            disabled={isProcessing}
            style={{ 
              padding: '10px 20px', 
              backgroundColor: '#f44336', 
              color: 'white', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: isProcessing ? 'not-allowed' : 'pointer' 
            }}
          >
            {cancelButtonText}
          </button>
        )}
      </div>
    </form>
  );
};

export default BasePaymentForm;