// App.js
import React, { useState, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EventTracker from './eventTracking';
import Login from './modules/Login';
import Dashboard from './modules/Dashboard/Dashboard';
import Sidebar from './modules/Sidebar';
import ChangeOrderContainer from './modules/ChangeOrderComponents/ChangeOrderContainer';
import CompanyPage from './modules/CompanyPage/CompanyPage';
import JobsPage from './modules/JobsPage';
import JobView from './modules/JobView/JobView';
import LandingPage from './modules/LandingPage/LandingPage';
import Assistant from './modules/Assistant/Assistant';
import EmployeePage from './modules/UsersPage/UsersPage';
import BillingComponent from './modules/Billing/Billing';
import SalesSignup from './modules/SalesSignup/SalesSignup';
import DevDashboard from './modules/Dashboard/DevDashboard';
import Legal from './modules/Legal';
import UserSignup from './modules/UserSignup/UserSignup';
import IntegrationPage from './modules/IntegrationPage/IntegrationPage';
import Support from './modules/SupportPage/Support';
import Analytics from './modules/Analytics/Analytics';
import './App.css';

const Demo = lazy(() => import('./modules/DemoPage/Demo'));

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

  return (
    <Router>
      <div className="App d-flex">
        <AppContent isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
    </Router>
  );
};

const AppContent = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const isDeveloperLoggedIn = useSelector((state) => state.user.isDeveloperLoggedIn);

  const showSidebar = (user || isDeveloperLoggedIn) && 
    !['/', '/login', '/sales-signup', '/dev-dashboard', '/legal', '/signup', '/demo', '/analytics'].includes(location.pathname);
  const isLandingOrLoginPage = ['/', '/login', '/sales-signup', '/legal', '/signup', '/demo'].includes(location.pathname);
  const mainContentClass = `main-content ${!showSidebar ? 'no-sidebar' : ''} ${!isSidebarOpen ? 'expanded' : ''} ${isLandingOrLoginPage ? 'landing-page-main-content' : ''}`;

  useEffect(() => {
    if (user) {
        EventTracker.trackPageView(user, location.pathname);
    }
}, [location.pathname, user]);

  return (
    <>
      {showSidebar && <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />}
      <div className={mainContentClass}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
          <Route path="/signup" element={!user ? <UserSignup /> : <Navigate to="/dashboard" />} />
          <Route path="/sales-signup" element={<SalesSignup />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/demo" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Demo />
            </Suspense>
          } />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                {user ? <Dashboard /> : <Navigate to={isDeveloperLoggedIn ? "/dev-dashboard" : "/login"} />}
              </ProtectedRoute>
            } 
          />
          <Route path="/change-order/:corNumber" element={<ProtectedRoute><ChangeOrderContainer /></ProtectedRoute>} />
          <Route path="/change-order/new" element={<ProtectedRoute><ChangeOrderContainer isNewOrder={true} /></ProtectedRoute>} />
          <Route path="/company" element={<ProtectedRoute><CompanyPage /></ProtectedRoute>} />
          <Route path="/jobs" element={<ProtectedRoute><JobsPage /></ProtectedRoute>} />
          <Route path="/job/:jobName" element={<ProtectedRoute><JobView /></ProtectedRoute>} />
          <Route path="/job/new" element={<ProtectedRoute><JobView isNewJob={true} /></ProtectedRoute>} />
          <Route path="/assistant" element={<ProtectedRoute><Assistant /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute><EmployeePage /></ProtectedRoute>} />
          <Route path="/billing" element={<ProtectedRoute><BillingComponent /></ProtectedRoute>} />
          <Route path="/integrations" element={<ProtectedRoute><IntegrationPage /></ProtectedRoute>} />
          <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
          <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
          <Route 
            path="/dev-dashboard" 
            element={
              <ProtectedRoute>
                {isDeveloperLoggedIn ? <DevDashboard /> : <Navigate to="/dashboard" />}
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </>
  );
};

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const isDeveloperLoggedIn = useSelector((state) => state.user.isDeveloperLoggedIn);
  const location = useLocation();

  if (!user && !isDeveloperLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default App;