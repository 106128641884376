// src/constants/config.js
import axios from 'axios';
import API_BASE_URL from './apiConfig';

let config = null;

export const getConfig = async () => {
  if (config) return config;
  
  try {
    const response = await axios.get(`${API_BASE_URL}/api/config`);
    config = response.data;
    return config;
  } catch (error) {
    console.error('Failed to fetch config:', error);
    return {};
  }
};