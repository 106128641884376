// PaymentMethodManager.js

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import API_BASE_URL from '../../constants/apiConfig';
import BasePaymentForm from './BasePaymentForm';

const SavePaymentMethodForm = ({ onSubmit, onCancel, customerId }) => {
  const handleSubmit = async (stripe, elements, email) => {
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/billing`,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
      redirect: 'if_required',
    });

    if (error) {
      throw new Error(error.message);
    }

    if (setupIntent.status === 'succeeded') {
      try {
        const response = await fetch(`${API_BASE_URL}/api/attach-payment-method`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: customerId,
            payment_method_id: setupIntent.payment_method,
            email: email,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to attach payment method');
        }

        await onSubmit();
      } catch (error) {
        throw new Error('Failed to update payment method. Please try again.');
      }
    } else {
      throw new Error('Unexpected setup intent status');
    }
  };

  return (
    <BasePaymentForm
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitButtonText="Save Payment Method"
      cancelButtonText="Cancel"
    />
  );
};

const PaymentMethodManager = ({ onPaymentMethodAdded }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const user = useSelector((state) => state.user.user);

  const fetchPaymentMethod = useCallback(async () => {
    if (user && user.customer_id) {
      try {
        setIsLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/payment-method?customer_id=${user.customer_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch payment method');
        }
        const method = await response.json();
        setPaymentMethod(method);
        if (onPaymentMethodAdded && method) {
          onPaymentMethodAdded();
        }
      } catch (error) {
        console.error('Error fetching payment method:', error);
        setErrorMessage('Failed to load payment information. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [user, onPaymentMethodAdded]);

  useEffect(() => {
    fetchPaymentMethod();
  }, [fetchPaymentMethod]);

  const handleUpdatePaymentMethod = async () => {
    try {
      await fetchPaymentMethod();
      setSuccessMessage('Payment method updated successfully!');
      closeModal();
    } catch (error) {
      console.error('Error updating payment method:', error);
      setErrorMessage('Failed to update payment method. Please try again.');
    }
  };

  const openModal = async () => {
    setIsModalOpen(true);
    setIsModalLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    if (user && user.customer_id) {
      const result = await fetchSetupIntent();
      if (result) {
        const { publishableKey } = result;
        const stripe = await loadStripe(publishableKey);
        setStripePromise(stripe);
      }
    } else {
      setIsModalLoading(false);
      setErrorMessage('User information is not available. Please try logging in again.');
    }
  };
  
  const fetchSetupIntent = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/create-payment-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          customer_id: user.customer_id,
          session_type: 'setup'
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to create SetupIntent');
      }
      const data = await response.json();
      setClientSecret(data.clientSecret);
      return {
        clientSecret: data.clientSecret,
        publishableKey: data.publishableKey
      };
    } catch (error) {
      console.error('Error creating SetupIntent:', error);
      setErrorMessage('Failed to initialize payment update. Please try again.');
      return null;
    } finally {
      setIsModalLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setClientSecret(null);
    setErrorMessage('');
    setIsModalLoading(false);
  };

  if (!user || !user.customer_id) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {successMessage && <div style={{ color: 'green', marginBottom: '10px' }}>{successMessage}</div>}
      {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
      {isLoading ? (
        <div>Loading payment information...</div>
      ) : paymentMethod ? (
        <div style={{ 
          border: '1px solid #e0e0e0', 
          borderRadius: '8px', 
          padding: '20px', 
          marginBottom: '20px',
          backgroundColor: '#f9f9f9'
        }}>
          <h3 style={{ marginTop: '0', marginBottom: '15px' }}>Current Payment Method</h3>
          <div style={{ marginBottom: '15px' }}>
            <p style={{ margin: '0', fontSize: '18px' }}>
              {paymentMethod.card.brand} ending in {paymentMethod.card.last4}
            </p>
            <p style={{ margin: '5px 0 0', color: '#666' }}>
              Expires {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
            </p>
            {paymentMethod.billing_email && (
              <p style={{ margin: '5px 0 0', color: '#666' }}>
                Billing Email: {paymentMethod.billing_email}
              </p>
            )}
          </div>
          <button 
            className="btn btn-primary"
            onClick={openModal}
          >
            Update Payment Method
          </button>
        </div>
      ) : (
        <div style={{ 
          border: '1px solid #e0e0e0', 
          borderRadius: '8px', 
          padding: '20px', 
          marginBottom: '20px',
          backgroundColor: '#f9f9f9',
          textAlign: 'center'
        }}>
          <p style={{ marginBottom: '15px' }}>No payment method on file</p>
          <button 
            className="btn btn-primary"
            onClick={openModal}
          >
            Add Payment Method
          </button>
        </div>
      )}

      {isModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            width: '90%',
            maxWidth: '600px',
            maxHeight: '90%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <h2>{paymentMethod ? 'Update' : 'Add'} Payment Method</h2>
              <button 
                onClick={closeModal}
                style={{
                  background: 'none',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer'
                }}
              >
                &times;
              </button>
            </div>
            {isModalLoading ? (
              <div>Loading payment form...</div>
            ) : clientSecret && stripePromise ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <SavePaymentMethodForm 
                  onSubmit={handleUpdatePaymentMethod}
                  onCancel={closeModal}
                  customerId={user.customer_id}
                />
              </Elements>
            ) : (
              <div>
                {errorMessage || 'Failed to load payment form. Please try again.'}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodManager;