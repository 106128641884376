// ReadOnlyJobView.js
import React from 'react';
import ChangeOrdersTable from '../shared/ChangeOrdersTable/ChangeOrdersTable';
import { formatPhoneNumberForDisplay } from '../shared/FormatPhoneNumber';

const JobValues = ({ jobData }) => (
  <div className="job-values-container bg-light p-4 rounded-3 shadow-sm">
    <div className="job-values-group mb-3 justify-content-between">
      <div className="job-values-item">
        <span className="job-values-label">Job Name:&nbsp;</span>
        <span>{jobData.jobName}</span>
      </div>
      <div className="job-values-item">
        <span className="job-values-label">General Contractor:&nbsp;</span>
        <span>{jobData.generalContractor}</span>
      </div>
      <div className="job-values-item">
        <span className="job-values-label">Job Status:&nbsp;</span>
        <span>{jobData.status}</span>
      </div>
    </div>
    {jobData.requireSuperintendentApproval && (
      <div className="job-values-group mb-3 justify-content-between">
        <div className="job-values-item">
          <span className="job-values-label">Superintendent Name:&nbsp;</span>
          <span>{jobData.superintendentName || 'Not provided'}</span>
        </div>
        <div className="job-values-item">
          <span className="job-values-label">Superintendent Phone:&nbsp;</span>
          <span>{jobData.superintendentPhoneNumber ? formatPhoneNumberForDisplay(jobData.superintendentPhoneNumber) : 'Not provided'}</span>
        </div>
      </div>
    )}
    <div>
      <h5 className="fw-normal mb-2">Emails receiving weekly COR reports:</h5>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {jobData.emails && jobData.emails.length > 0 ? (
              jobData.emails.map((email, index) => (
                <tr key={index}>
                  <td>{email}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No current emails</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const ReadOnlyJobView = ({ jobData, isNewJob, changeOrders, errors }) => {
  return (
    <>
      {jobData && !isNewJob ? (
        <JobValues jobData={jobData} />
      ) : (
        <div>Please fill in the job details.</div>
      )}

      {jobData && !isNewJob && (
        <>
          <h3 className="mt-4">Change Order Requests</h3>
          {errors.changeOrders && errors.changeOrders.response?.status !== 404 && (
            <div className="alert alert-warning" role="alert">
              Error fetching change orders: {errors.changeOrders.message}
            </div>
          )}
          
          {changeOrders.length > 0 ? (
            <div className="job-view-change-order-table">
              <ChangeOrdersTable
                changeOrders={changeOrders}
                excludeJobNames={true}
              />
            </div>
          ) : (
            <div>No change orders to display for this job.</div>
          )}
        </>
      )}
    </>
  );
};

export default ReadOnlyJobView;