import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditValues } from '../redux/changeOrderActions';
import './ScheduleExtension.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditableScheduleExtension = () => {
 const dispatch = useDispatch();
 const { editValues } = useSelector((state) => state.changeOrder);
 const { schedule_extension } = editValues;

 const createDropdownOptions = () => {
   let options = [
     <option key={0} value={0}>
       None
     </option>,
   ];
   for (let i = 1; i <= 100; i++) {
     options.push(
       <option key={i} value={i}>
         {i}
       </option>
     );
   }
   return options;
 };

 const handleExtensionChange = (field, value) => {
   dispatch(updateEditValues({ ...editValues, schedule_extension: { ...schedule_extension, [field]: value } }));
 };

// EditableScheduleExtension.js
return (
  <div className="schedule-extension-component editable-schedule-extension">
    <div className="extension-row">
      <div className="detail-label">Schedule Extension Needed:</div>
      <div className="ranges-row">
        Days:
        <select
          className="form-control"
          value={schedule_extension.Days || 0}
          onChange={(e) => handleExtensionChange('Days', parseInt(e.target.value))}
        >
          {createDropdownOptions()}
        </select>
      </div>
      <div className="ranges-row">
        Weeks:
        <select
          className="form-control" 
          value={schedule_extension.Weeks || 0}
          onChange={(e) => handleExtensionChange('Weeks', parseInt(e.target.value))}
        >
          {createDropdownOptions()}
        </select>
      </div>
      <div className="ranges-row">
        Months:
        <select
          className="form-control"
          value={schedule_extension.Months || 0}
          onChange={(e) => handleExtensionChange('Months', parseInt(e.target.value))}
        >
          {createDropdownOptions()}
        </select>
      </div>
    </div>
  </div>
 );
};

export default EditableScheduleExtension;