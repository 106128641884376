// EditableJobView.js
import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'bootstrap';
import './JobView.css';
import { formatPhoneNumberForDisplay } from '../shared/FormatPhoneNumber';

const EditableJobView = ({ editedJobData, setEditedJobData, setIsFormValid, customerRequiresSuperintendentApproval }) => {
  const statuses = ['Active', 'Inactive'];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
  }, []);

  useEffect(() => {
    // Format the phone number when it changes
    if (editedJobData.superintendentPhoneNumber) {
      const formattedNumber = formatPhoneNumberForDisplay(editedJobData.superintendentPhoneNumber);
      if (formattedNumber !== editedJobData.superintendentPhoneNumber) {
        setEditedJobData(prevData => ({
          ...prevData,
          superintendentPhoneNumber: formattedNumber
        }));
      }
    }
  }, [editedJobData.superintendentPhoneNumber, setEditedJobData]);

  const handleEmailChange = (index, value) => {
    const updatedEmails = editedJobData.emails.map((email, i) => i === index ? value : email);
    setEditedJobData({ ...editedJobData, emails: updatedEmails });
  };

  const handleAddEmail = () => {
    setEditedJobData({ ...editedJobData, emails: [...editedJobData.emails, ''] });
  };

  const handleRemoveEmail = (index) => {
    const filteredEmails = editedJobData.emails.filter((_, i) => i !== index);
    setEditedJobData({ ...editedJobData, emails: filteredEmails });
  };

  const handleSuperintendentApprovalChange = (e) => {
    setEditedJobData({
      ...editedJobData,
      requireSuperintendentApproval: e.target.checked
    });
    // Clear validation errors when toggling the checkbox
    setValidationErrors({});
    validateForm();
  };

  const handleSuperintendentPhoneChange = (e) => {
    const formattedNumber = formatPhoneNumberForDisplay(e.target.value);
    setEditedJobData({ ...editedJobData, superintendentPhoneNumber: formattedNumber });
    validateForm();
  };

  const validateForm = useCallback(() => {
    const errors = {};
  
    if (customerRequiresSuperintendentApproval && editedJobData.requireSuperintendentApproval) {
      if (!editedJobData.superintendentName.trim()) {
        errors.superintendentName = 'Superintendent Name is required';
      }
      if (!editedJobData.superintendentPhoneNumber.trim()) {
        errors.superintendentPhoneNumber = 'Superintendent Phone is required';
      } else {
        const phoneDigits = editedJobData.superintendentPhoneNumber.replace(/\D/g, '');
        if (phoneDigits.length !== 10) {
          errors.superintendentPhoneNumber = 'Phone number must have 10 digits';
        }
      }
    }
  
    setValidationErrors(errors);
    const formIsValid = Object.keys(errors).length === 0;
    setIsFormValid(formIsValid);
    return formIsValid;
  }, [editedJobData, setIsFormValid, customerRequiresSuperintendentApproval]);

  useEffect(() => {
    validateForm();
  }, [validateForm, editedJobData.requireSuperintendentApproval, editedJobData.superintendentName, editedJobData.superintendentPhoneNumber]);

  return (
    <form className="job-values-container bg-light p-4 rounded-3 shadow-sm">
      <div className="d-flex mb-3 align-items-center">
        <label htmlFor="jobName" className="form-label job-values-label">Job Name:</label>
        <input
          type="text"
          className="form-control job-values-input"
          id="jobName"
          name="jobName"
          value={editedJobData.jobName}
          onChange={(e) => setEditedJobData({ ...editedJobData, jobName: e.target.value })}
        />
      </div>
      <div className="d-flex mb-3 align-items-center">
        <label htmlFor="generalContractor" className="form-label job-values-label">General Contractor:</label>
        <input
          type="text"
          className="form-control job-values-input"
          id="generalContractor"
          name="generalContractor"
          value={editedJobData.generalContractor}
          onChange={(e) => setEditedJobData({ ...editedJobData, generalContractor: e.target.value })}
        />
      </div>
      <div className="d-flex mb-3 align-items-center">
        <label htmlFor="jobStatus" className="form-label job-values-label">Status:</label>
        <select
          id="jobStatus"
          className="form-select job-values-input"
          value={editedJobData.status}
          onChange={(e) => setEditedJobData({ ...editedJobData, status: e.target.value })}
        >
          {statuses.map((status, index) => (
            <option key={index} value={status}>{status}</option>
          ))}
        </select>
      </div>
      {customerRequiresSuperintendentApproval && (
        <>
          <div className="d-flex mb-3 align-items-center">
            <label htmlFor="requireSuperintendentApproval" className="form-check-label job-values-label">
              Require Superintendent Approval:
            </label>
            <input
              type="checkbox"
              className="form-check-input ms-2"
              id="requireSuperintendentApproval"
              checked={editedJobData.requireSuperintendentApproval}
              onChange={handleSuperintendentApprovalChange}
            />
            <span 
              className="tooltip-icon ms-2"
              data-bs-toggle="tooltip" 
              data-bs-placement="top" 
              title="When checked, both superintendent name and phone number must be filled out."
            ></span>
          </div>
          <div className="d-flex mb-3 align-items-center">
            <label htmlFor="superintendentName" className="form-label job-values-label">Superintendent Name:</label>
            <div className="flex-grow-1 position-relative">
              <input
                type="text"
                className={`form-control job-values-input ${validationErrors.superintendentName ? 'is-invalid' : ''}`}
                id="superintendentName"
                name="superintendentName"
                value={editedJobData.superintendentName || ''}
                onChange={(e) => setEditedJobData({ ...editedJobData, superintendentName: e.target.value })}
                disabled={!editedJobData.requireSuperintendentApproval}
              />
              {validationErrors.superintendentName && (
                <div className="invalid-feedback">{validationErrors.superintendentName}</div>
              )}
            </div>
          </div>
          <div className="d-flex mb-3 align-items-center">
            <label htmlFor="superintendentPhoneNumber" className="form-label job-values-label">Superintendent Phone:</label>
            <div className="flex-grow-1 position-relative">
              <input
                type="tel"
                className={`form-control job-values-input ${validationErrors.superintendentPhoneNumber ? 'is-invalid' : ''}`}
                id="superintendentPhoneNumber"
                name="superintendentPhoneNumber"
                value={editedJobData.superintendentPhoneNumber || ''}
                onChange={handleSuperintendentPhoneChange}
                disabled={!editedJobData.requireSuperintendentApproval}
                placeholder="(123) 456-7890"
              />
              {validationErrors.superintendentPhoneNumber && (
                <div className="invalid-feedback">{validationErrors.superintendentPhoneNumber}</div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="mb-3">
        <label className="form-label job-values-label">Emails receiving weekly COR reports:</label>
        {editedJobData.emails.map((email, index) => (
          <div key={index} className="d-flex mb-2 align-items-center">
            <input
              type="email"
              className={`form-control job-values-input ${!emailRegex.test(email) ? 'is-invalid' : ''}`}
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
            />
            <button type="button" className="btn btn-danger ms-2" onClick={() => handleRemoveEmail(index)}>Remove</button>
            {!emailRegex.test(email) && <div className="invalid-feedback">Invalid email address</div>}
          </div>
        ))}
        <div className="btn-group">
          <button type="button" className="btn btn-secondary" onClick={handleAddEmail}>Add Email</button>
        </div>
      </div>
    </form>
  );
};

export default EditableJobView;