import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userLoggedIn, setDeveloperLoggedIn } from '../redux/actions/userActions';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import { authApi } from '../constants/axiosConfig';

const DEVELOPER_PHONE_NUMBER = '+19876543210';

const formatPhoneNumberForDisplay = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const formatPhoneNumberForE164 = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  return `+1${phoneNumber.slice(-10)}`;
};

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [rememberDevice, setRememberDevice] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    const formattedNumber = formatPhoneNumberForDisplay(e.target.value);
    setPhoneNumber(formattedNumber);
  };

  const handleAuthCodeChange = (e) => {
    setAuthCode(e.target.value);
  };

  const handleTotpCodeChange = (e) => {
    const value = e.target.value.trim();
    setTotpCode(value);
  };  

  const handleRememberDeviceChange = (e) => {
    setRememberDevice(e.target.checked);
  };

  const handleRequestCode = async (e) => {
    e.preventDefault();
    const e164PhoneNumber = formatPhoneNumberForE164(phoneNumber);
    if (e164PhoneNumber.length !== 12) {
      setError('Please enter a valid 10-digit phone number.');
      return;
    }

    try {
      const response = await authApi.post('/login', { phone: e164PhoneNumber });
      if (response.data.success) {
        setStep(2);
        setError('');
      } else {
        setError(response.data.message || 'Phone number not found.');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred while processing your request.');
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    const e164PhoneNumber = formatPhoneNumberForE164(phoneNumber);
  
    const codeToSend = e164PhoneNumber === DEVELOPER_PHONE_NUMBER ? totpCode : authCode;
  
    const data = {
      phone: e164PhoneNumber,
      code: codeToSend,
      remember_device: rememberDevice
    };
  
    try {
      const response = await authApi.post('/verify-login', data);
  
      if (response.data.success) {
        if (response.data.session_token) {
          document.cookie = `sessionToken=${response.data.session_token}; path=/; max-age=${30*24*60*60}`;
          document.cookie = `phoneNumber=${e164PhoneNumber}; path=/; max-age=${30*24*60*60}`;
        }
  
        if (e164PhoneNumber === DEVELOPER_PHONE_NUMBER) {
          dispatch(setDeveloperLoggedIn());
          navigate('/dev-dashboard');
        } else {
          const userData = response.data.user;
          dispatch(userLoggedIn(userData));
          if (response.data.session_token) {
            authApi.defaults.headers.common['Authorization'] = `Bearer ${response.data.session_token}`;
          }
          navigate('/dashboard');
        }
      } else {
        console.error('Login failed:', response.data.message);
        setError(response.data.message || 'Invalid authentication code.');
      }
    } catch (error) {
      console.error('Login error:', error);
      console.error('Error response:', error.response);
      setError(error.response?.data?.message || 'An error occurred while verifying the authentication code.');
    }
  };

  return (
    <div className="container login-container">
      <Link to="/" className="btn btn-outline-secondary back-button">
        &larr; Back
      </Link>
      <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="col-12 col-md-6 login-column">
          <div className="card shadow login-card">
            <div className="card-body login-card-body">
              <h3 className="card-title text-center">Login</h3>
              {step === 1 ? (
                <form onSubmit={handleRequestCode}>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      placeholder="(123) 456-7890"
                      required
                    />
                  </div>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary">Request Authorization Code</button>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleVerifyCode}>
                  {formatPhoneNumberForE164(phoneNumber) === DEVELOPER_PHONE_NUMBER ? (
                    <div className="mb-3">
                      <label htmlFor="totpCode" className="form-label">Google Authenticator Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="totpCode"
                        value={totpCode}
                        onChange={handleTotpCodeChange}
                        placeholder="Enter 6-digit code"
                        required
                      />
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        <label htmlFor="authCode" className="form-label">Authentication Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="authCode"
                          value={authCode}
                          onChange={handleAuthCodeChange}
                          placeholder="Enter 6-digit code"
                          required
                        />
                      </div>
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="rememberDevice"
                          checked={rememberDevice}
                          onChange={handleRememberDeviceChange}
                        />
                        <label className="form-check-label" htmlFor="rememberDevice">Remember this device for 30 days</label>
                      </div>
                    </>
                  )}
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary">
                      {formatPhoneNumberForE164(phoneNumber) === DEVELOPER_PHONE_NUMBER ? 'Login as Developer' : 'Verify Code'}
                    </button>
                  </div>
                </form>
              )}
              {error && <div className="text-danger mt-3">{error}</div>}
              <div className="mt-3 text-center">
                <Link to="/legal" className="policy-link">View Privacy Policy & EULA</Link>
              </div>
              <div className="mt-2 text-center">
                Don't have an account? <Link to="/signup" className="signup-link">Sign Up</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;